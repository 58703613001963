import styles from "./css/ModuleInfo.module.css"

import Markdown from "markdown-to-jsx"

export default function ModuleInfo({ module }) {
    return (
        <div className={styles.container}>
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h2>How does it work?</h2>
                </div>
                <Markdown className={styles.text}>{module.how_it_works}</Markdown>
                <div className={styles.buttonWrap}>
                    <a href="/contact">Show me how</a>
                </div>
            </div>
        </div>
    )
}