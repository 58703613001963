import styles from "./AddressPortalLandingPage.module.css"

import Navbar from "../common/Navbar";
import Hero from "../common/Hero";
import Section from "../common/Section";
import CardRow from "../common/CardRow";
import DarkCard from "../common/DarkCard";
import CardIcon from "../common/CardIcon";
import Contact from "../common/Contact";
import Footer from "../common/Footer";
import FooterMobile from "../common/FooterMobile";
import DownloadModal from "../common/DownloadModal";
import UtilityCompanies from "../common/UtilityCompanies";

export default function AddressPortalLandingPage() {
    return (
        <div className={styles.page}>
            <Navbar />
            <Hero>
                <div className={styles.thumbTopContainer}>
                    <div className={styles.thumbprintTop} />
                </div>
                <div className={styles.logoWrap}>
                    <img src="/images/address-portal-landing-page/img/address-portal-logo.png" alt="Address Portal" />
                </div>
                <h1>Identify address issues, increase operational efficiency, identify occupiers for vacants</h1>
                <DownloadModal url="/files/IDenteq_BD Portal Overview.pdf" buttonText="Learn how" text="Enter your name & email to download more details and qualify for a free trial or POC" page="address-portal" hoverColor="white" />
            </Hero>
            <Section className={styles.cardSection}>
                <div className={styles.thumbBottomContainer}>
                    <div className={styles.thumbprintBottom} />
                </div>
                <h2>
                    Addresses are key for Retailers to identify occupiers and 
                    maximise occupancy rates. Address Portal allows you to 
                    visually see which SPIDs require work to ensure they are 
                    valid, accurate and correct in easy to use modules
                </h2>
                <CardRow>
                    <DarkCard>
                        <CardIcon src="/images/address-portal-landing-page/icons/address-portal-icons/identeq-folder.svg" alt="15 quality data sources" />
                        <p>
                            Use our 11 modules understand the address issues, 
                            and correct using AddressBase VOA & Council Tax data
                        </p>
                    </DarkCard>
                    <DarkCard>
                        <CardIcon src="/images/address-portal-landing-page/icons/address-portal-icons/identeq-shield.svg" alt="15 quality data sources" />
                        <p>
                            Increase billing & reduce leakage with accurate 
                            address and identifying occupiers for vacant properties
                        </p>
                    </DarkCard>
                    <DarkCard>
                        <CardIcon src="/images/address-portal-landing-page/icons/address-portal-icons/identeq-graph.svg" alt="15 quality data sources" />
                        <p>
                            League tables to show standings in the Wholesaler market
                        </p>
                    </DarkCard>
                </CardRow>
            </Section>
            <hr />
            <Section className={styles.downloadSection}>
                <div className={styles.splitSection}>
                    <div className={styles.left}>
                        <h2>
                            This innovative and bespoke approach to address cleansing, 
                            gives you all the data you need in one easy to use screen, 
                            which increases efficiency and throughput
                            <br />
                            <br />
                            Take a look today</h2>
                        <DownloadModal url="/files/IDenteq_BD Portal Overview.pdf" text="Enter your name & email to download more details and qualify for a free trial or POC" buttonText="Download" page="address-portal" hoverColor="black" />
                    </div>
                    <div className={styles.right}>
                        <img src="/images/address-portal-landing-page/img/address-portal-ui.png" alt="" />
                    </div>
                </div>
            </Section>
            <hr />
            <Section className={styles.logosSection}>
                <h2>Some of the people we work with</h2>
                <UtilityCompanies />
            </Section>
            <Contact page="address-portal" />
            <Footer className={styles.desktopOnly} />
            <FooterMobile className={styles.mobileOnly} />
        </div>
    )
}