import styles from "./css/Hero.module.css"

import Section from "./Section"

export default function Hero({ children }) {
    return (
        <Section className={styles.hero} dark>
            {children}
        </Section>
    )
}