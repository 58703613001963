import styles from "./BDFeatures.module.css"

import "animate.css"
import { AnimationOnScroll } from 'react-animation-on-scroll';

import PowerfulDataSetsIcon from '../../img/icons/Powerful-Data-Sets-Icon.png';
//import DataAggregationIcon from '../../img/icons/Data-Aggregation-Icon.png';
import DataAggregationIcon from '../../img/icons/Identify-Prevent-Fraud.png';
import WorkflowEnableDataIcon from '../../img/icons/Workflow-Enable-Data-Icon.png';
import SelfServiceReportingIcon from '../../img/icons/Self-Service-Reporting.png';

export default function BDFeatures() {
    return (
        <div className={styles.background}>
            <div className={styles.boxesWrap} >
                {/* Data sets */}
                <div className={styles.box} >
                    <div className={styles.boxImgWrap} >
                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                            <img loading="lazy" decoding="async" width="90" height="79"
                                src={PowerfulDataSetsIcon}
                                alt="Icon Image for powerful data sets" />
                        </AnimationOnScroll>
                    </div>

                    <div className={styles.boxTextWrap} >
                        <p>Business Data Portal introduces innovative and powerful data sets to identify and manage COT/MiMO.</p>
                    </div>
                </div>
                {/* Data sets - END */}

                {/* Data aggregated */}
                <div className={styles.box} >
                    <div className={styles.boxImgWrap} >
                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                            <img loading="lazy" decoding="async" width="90" height="90"
                                src={DataAggregationIcon}
                                alt="Icon Image for identify and prevent fraud" />
                        </AnimationOnScroll>
                    </div>

                    <div className={styles.boxTextWrap} >
                        <p>Data is aggregated to identify and prevent fraud through proactive alerts.</p>
                    </div>
                </div>
                {/* Data aggregated - END */}

                {/* Enable data */}
                <div className={styles.box} >
                    <div className={styles.boxImgWrap} >
                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                            <img loading="lazy" decoding="async" width="90" height="89"
                                src={WorkflowEnableDataIcon}
                                alt="Icon Image for workflow enable data" />
                        </AnimationOnScroll>
                    </div>

                    <div className={styles.boxTextWrap} >
                        <p>The platform includes workflow to enable data driven processes to be managed, or can be called via APIs into existing CRM/Billing systems.</p>
                    </div>
                </div>
                {/* Enable data - END */}

                {/* Reporting analysis */}
                <div className={styles.box} >
                    <div className={styles.boxImgWrap} >
                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                            <img loading="lazy" decoding="async" width="77" height="90"
                                src={SelfServiceReportingIcon}
                                alt="Icon Image for Self Service Reporting" />
                        </AnimationOnScroll>
                    </div>

                    <div className={styles.boxTextWrap} >
                        <p>Business Data Portal includes self-service reporting and analysis tools, designed to support your operational processes.</p>
                    </div>
                </div>
                {/* Reporting analysis - END */}
            </div>
        </div>
    )
}