import styles from "./css/FooterMobile.module.css"

import LinkedInIcon from '@mui/icons-material/LinkedIn';

import Section from "./Section"

export default function FooterMobile({ className }) {

    return (
        <Section className={`${styles.footer} ${className}`} style={{paddingTop: "70px", paddingBottom: 0}} dark>
            <div className={styles.above}>
                <div className={styles.logoWrap}>
                    <img src="/images/bd-portal-landing-page/img/Identeq-logo-half-colour.svg" alt="IDenteq" />
                </div>
                <div className={styles.footerNav}>
                    <a href="https://www.linkedin.com/company/identeq-ltd/"><LinkedInIcon className={styles.icon} /></a>
                    <h4 className={styles.followUs}>Follow Us</h4>
                </div>
                <div className={styles.footerNav} hidden>
                    <h4>Navigate</h4>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">About</a></li>
                        <li><a href="#">Industries</a></li>
                        <li><a href="#">Solutions</a></li>
                    </ul>
                </div>
                <div className={styles.footerNav} hidden>
                    <h4>Useful Links</h4>
                    <ul>
                        <li><a href="#">COT Portal</a></li>
                        <li><a href="#">BD Portal</a></li>
                        <li><a href="#">Get in Touch</a></li>
                    </ul>
                </div>
                <p><small>© IDenteq Ltd 2022 | All Rights Reserved | <a href="/privacy-policy">Privacy Policy</a></small></p>
            </div>
            <hr className={styles.footerDivider} />
            <div className={styles.certLogos}>
                <div className={styles.imgWrap}>
                    <img src="/images/bd-portal-landing-page/img/footer-icons/qms.png" alt="QMS" />
                </div>
                <div className={styles.imgWrap}>
                    <img src="/images/bd-portal-landing-page/img/footer-icons/cyber-essentials.png" alt="QMS" />
                </div>
                <div className={styles.imgWrap}>
                    <img src="/images/bd-portal-landing-page/img/footer-icons/iasme.png" alt="QMS" />
                </div>
                <div className={styles.imgWrap}>
                    <img src="/images/bd-portal-landing-page/img/footer-icons/cima.png" alt="QMS" />
                </div>
            </div>
        </Section>
    )
}