import styles from "./ArrangeDemo.module.css"

export default function ArrangeDemo({ page, demoRef }) {
    let blurb = ""

    switch (page) {
        case "water":
            blurb = "With our years of experience in utilities and our advanced data aggregation technology, we hope to become your partner in navigating the turbulent currents of water provision."
            break;
        case "energy":
            blurb = "With our years of experience in utilities and our advanced data aggregation technology, we hope to become your partner in navigating the complexities of energy provision."    
            break;
        default: 
            blurb = ""
    }

    return (
        <div className={styles.background}>
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h2>Arrange a demo</h2>
                </div>
                <p className={styles.blurb} >{blurb}</p>
                <p>Book a demo with us today!</p>
                <div className={styles.buttonWrap} >
                    <a href="#demo" onClick={() => demoRef.current.scrollIntoView({behavior: "smooth"})}>Book a Demo</a>
                </div>
            </div>

        </div>
    )
}