import styles from "./css/PostContent.module.css"

import Markdown from 'markdown-to-jsx'

export default function PostContent({ post }) {
    const prevPost = post.prev_post
    const nextPost = post.next_post

    return (
        <div className={styles.postBody}>
            <div className={styles.postText}>
                <Markdown className={post.post_type == "Report" ? styles.reportContent : styles.blogContent}>{post.content}</Markdown>
            </div>
            {post.download_path ? <a href={post.download_path} className={styles.downloadButton} download>Download</a> : <></> }
            <div className={styles.postNavigation}>
                {prevPost?.slug ? <a href={"/publications/" + prevPost.slug} className={styles.navPrev}>← Prev: {prevPost.title}</a> : <></> }
                {nextPost?.slug ? <a href={"/publications/" + nextPost.slug} className={styles.navNext}>Next: {nextPost.title} →</a> : <></> }
            </div>
        </div>
    )
}