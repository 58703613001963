import ReactGA from 'react-ga4'

let isInitialized = false

export const initGA = () => {
    if(!isInitialized) {
        ReactGA.initialize("G-B2V274NW4J", {
            gaOptions: {
                debug_mode: false,
            },
            gtagOptions: {
                debug_mode: false,
            },
        })
        isInitialized = true
    }
}

export const logPageView = () => {
    ReactGA.send({ 
        hitType: 'pageview', 
        page: window.location.pathname + window.location.search,
        debug_mode: false,
    })
}

export const logEvent = ({ category, action, label }) => {
    ReactGA.event({
        category,
        action,
        label,
    })
}