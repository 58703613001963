import React, { Component } from 'react'

import "animate.css"
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Header from './/../components/header/header'
import Footer from './/../components/footer/footer'
import Container from './/../components/container/container'
import Contact from './/../components/contact/contact'

import './css/technology.css';

// Saas Icons
import ScalabilityIcon from '../img/icons/Scalability.jpg';
import HighAvailablityIcon from '../img/icons/High-Availablity.jpg';
import RealTimeIcon from '../img/icons/Real-Time.jpg';
import APIsIcon from '../img/icons/APIs.jpg';
import ServerlessIcon from '../img/icons/Serverless.jpg';
import ContinuousImprovementIcon from '../img/icons/Continuous-Improvement.jpg';

// * Our Stack Icons *
// Processing
import AWSLambdaIcon from '../img/technology/AWS-Lambda-Icon.png';
import AWSBatchIcon from '../img/technology/AWS-Batch-icon.png';
import AmazonRDSIcon from '../img/technology/Amazon-RDS-icon.png';
import AmazonRoute53Icon from '../img/technology/Amazon-Route-53-icon.png';
import AmazonEC2Icon from '../img/technology/Amazon-EC2-Icon.png';
import AmazonSQSIcon from '../img/technology/Amazon-SQS-icon.png';
import AmazonAPIGatewayIcon from '../img/technology/Amazon-AP1-Gateway-icon.png';
import AmazonS3Icon from '../img/technology/Amazon-S3-icon.png';
// Security
import AmazonCloudWatchIcon from '../img/technology/Amazon-Cloud-Watch-Icon.png';
import AWSConfigIcon from '../img/technology/AWS-Config-icon.png';
import AWSWAFIcon from '../img/technology/AWS-WAF-Icon.png';
import AmazonCloudTrailIcon from '../img/technology/Amazon-Cloud-Trail-icon.png';
import AmazonInspectorIcon from '../img/technology/Amazon-Inspector-icon.png';
import AmazonGuardDutyIcon from '../img/technology/Amazon-Guard-Duty-icon.png';
// Management and Deployment
import AWSCodePipelineIcon from '../img/technology/AWS-Code-Pipeline-icon.png';
import AzureDevOpsIcon from '../img/technology/Azure-DevOps-icon.png';
import AsanaIcon from '../img/technology/Asana-Icon.png';


export default class Technology extends Component {

    componentDidMount() {
        document.title = 'Technology | IDenteq'
    }

    render() {
        return (
            <Container>
                <Header page="technology" />
                <Container>
                    {/* Technology content */}

                    {/* Modern Technology */}
                    <div id="technology_ModernTechnology_wrap">
                        <div className="technology-content-wrap">
                            <div className="technology-header-wrap">
                                <h1>Technology</h1>
                            </div>
                            <p>Modern technology used to its full potential. IDenteq utilises cloud-based technology to develop, host and manage our software solutions.</p>
                        </div>
                    </div>
                    {/* Modern Technology - END */}

                    {/* SaaS */}
                    <div id="technology_SaaS_wrap">
                        <div className="technology-content-wrap">
                            <div className="technology-header-wrap">
                                <h2>Software-as-a-Service (SaaS)</h2>
                            </div>
                            <p id="technology_SaaS_blurb">All IDenteq software is service based and hosted in the cloud – The COT Portal leverages Amazon Web Services (AWS) architecture to provide exceptional resilience and scalability, meaning that down time and latency are not part of the user experience.</p>

                            <div id="technology_SaaS_box_wrap">
                                {/* Scalability */}
                                <div class="technology_SaaS_box">
                                    <div class="technology_SaaS_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="82" height="90"
                                                src={ScalabilityIcon}
                                                alt="Icon Image for scalability" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="technology_SaaS_box_text_wrap">
                                        <h4 class="technology_SaaS_box_header">Scalability</h4>
                                        <div class="technology_SaaS_box_description">
                                            <p>Cloud platforms offer many benefits and one of the most important is the ability to add computing power or infrastructure automatically. By leveraging our cloud computing resources, the COT Portal handles peaks in user demand and data processing seamlessly.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Scalability - END */}

                                {/* High Availability */}
                                <div class="technology_SaaS_box">
                                    <div class="technology_SaaS_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="82" height="92"
                                                src={HighAvailablityIcon}
                                                alt="Icon Image for High Availability" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="technology_SaaS_box_text_wrap">
                                        <h4 class="technology_SaaS_box_header">High Availability</h4>
                                        <div class="technology_SaaS_box_description">
                                            <p>IDenteq’s infrastructure is multi-location, configured to reduce any downtime and requires little to no human interaction to restore operation to our systems – avoiding impacts on business continuity.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* High Availability - END */}

                                {/* Real-Time */}
                                <div class="technology_SaaS_box">
                                    <div class="technology_SaaS_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="85" height="92"
                                                src={RealTimeIcon}
                                                alt="Icon Image for Real-Time" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="technology_SaaS_box_text_wrap">
                                        <h4 class="technology_SaaS_box_header">Real-Time</h4>
                                        <div class="technology_SaaS_box_description">
                                            <p>The COT Portal uses event driven architecture to enact real-time transactions and processing – no need to wait for file transfers and batch processes.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Real-Time - END */}

                                {/* APIs */}
                                <div class="technology_SaaS_box">
                                    <div class="technology_SaaS_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="93" height="92"
                                                src={APIsIcon}
                                                alt="Icon Image for APIs" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="technology_SaaS_box_text_wrap">
                                        <h4 class="technology_SaaS_box_header">APIs</h4>
                                        <div class="technology_SaaS_box_description">
                                            <p>Our APIs make the integration of COT Portal data with core systems or back end databases simple, and provide opportunities for innovation.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* APIs - END */}

                                {/* Serverless */}
                                <div class="technology_SaaS_box">
                                    <div class="technology_SaaS_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="101" height="92"
                                                src={ServerlessIcon}
                                                alt="Icon Image for Serverless" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="technology_SaaS_box_text_wrap">
                                        <h4 class="technology_SaaS_box_header">Serverless</h4>
                                        <div class="technology_SaaS_box_description">
                                            <p>Using serverless technology, the COT Portal comes with automatic scaling, built-in high availability, value for money – all without managing any servers.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Serverless - END */}

                                {/* Continuous Improvement */}
                                <div class="technology_SaaS_box">
                                    <div class="technology_SaaS_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="90" height="95"
                                                src={ContinuousImprovementIcon}
                                                alt="Icon Image for Continuous Improvement" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="technology_SaaS_box_text_wrap">
                                        <h4 class="technology_SaaS_box_header">Continuous Improvement</h4>
                                        <div class="technology_SaaS_box_description">
                                            <p>Product development is a continuous process; using an agile methodology, new features and enhancements are deployed through a fortnightly release cycle.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Continuous Improvement - END */}
                            </div>
                        </div>
                    </div>
                    {/* SaaS - END */}

                    {/* Our Stack */}
                    <div id="technology_OurStack_wrap">
                        <div className="technology-content-wrap">
                            <div className="technology-header-wrap">
                                <h2>Our Stack</h2>
                            </div>

                            <div class="technology_OurStack_divider"></div>

                            {/* Processing */}
                            <div class="technology_OurStack_row">
                                <div class="technology_OurStack_column">
                                    <h3>Processing</h3>
                                </div>

                                <div class="technology_OurStack_column">
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AWSLambdaIcon}
                                                    alt="Icon Image for AWS Lambda" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>AWS Lambda</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AWSBatchIcon}
                                                    alt="Icon Image for AWS Batch" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>AWS Batch</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonRDSIcon}
                                                    alt="Icon Image for Amazon RDS" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon RDS</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap ourstack-last-row">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonRoute53Icon}
                                                    alt="Icon Image for Amazon Route 53" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon Route 53</h4></div>
                                    </div>
                                </div>

                                <div class="technology_OurStack_column">
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonEC2Icon}
                                                    alt="Icon Image for Amazon EC2" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon EC2</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonSQSIcon}
                                                    alt="Icon Image for Amazon Simple Queue Service" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon Simple Queue Service</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonAPIGatewayIcon}
                                                    alt="Icon Image for Amazon API Gateway" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon API Gateway</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap ourstack-last-row">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonS3Icon}
                                                    alt="Icon Image for Amazon S3" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon S3</h4></div>
                                    </div>
                                </div>
                            </div>
                            {/* Processing - END */}

                            <div class="technology_OurStack_divider"></div>

                            {/* Security */}
                            <div class="technology_OurStack_row">
                                <div class="technology_OurStack_column">
                                    <h3>Security</h3>
                                </div>
                                <div class="technology_OurStack_column">
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonCloudWatchIcon}
                                                    alt="Icon Image for Amazon CloudWatch" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon CloudWatch</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AWSConfigIcon}
                                                    alt="Icon Image for AWS Config" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>AWS Config</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap ourstack-last-row">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AWSWAFIcon}
                                                    alt="Icon Image for AWS WAF" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>AWS WAF</h4></div>
                                    </div>
                                </div>
                                <div class="technology_OurStack_column">
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonCloudTrailIcon}
                                                    alt="Icon Image for Amazon CloudTrail" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon CloudTrail</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonInspectorIcon}
                                                    alt="Icon Image for Amazon Inspector" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon Inspector</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap ourstack-last-row">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AmazonGuardDutyIcon}
                                                    alt="Icon Image for Amazon GuardDuty" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Amazon GuardDuty</h4></div>
                                    </div>
                                </div>
                            </div>
                            {/* Security - END */}

                            <div class="technology_OurStack_divider"></div>

                            {/* Management and Deployment */}
                            <div class="technology_OurStack_row">
                                <div class="technology_OurStack_column">
                                    <h3>Management and Deployment</h3>
                                </div>
                                <div class="technology_OurStack_column">
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AWSCodePipelineIcon}
                                                    alt="Icon Image for AWS CodePipeline" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>AWS CodePipeline</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AzureDevOpsIcon}
                                                    alt="Icon Image for Azure DevOps" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Azure DevOps</h4></div>
                                    </div>
                                    <div class="technology_OurStack_inner_wrap ourstack-last-row">
                                        <div className="ourstack-img-wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="80" height="80"
                                                    src={AsanaIcon}
                                                    alt="Icon Image for Asana" />
                                            </AnimationOnScroll>
                                        </div>
                                        <div><h4>Asana</h4></div>
                                    </div>
                                </div>
                            </div>
                            {/* Management and Deployment - END */}

                            <div class="technology_OurStack_divider"></div>

                        </div>
                    </div>
                    {/* Our Stack - END */}

                    {/* Technology content - END */}
                </Container>
                <div className="contact-wrap">
                    <Contact page="technology" />
                </div>
                <Footer page="technology" />
            </Container>
        );
    }
}
