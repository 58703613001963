import styles from "./css/PostSuggestions.module.css"

import Snippet from "./Snippet"

export default function PostContent({ post }) {

    const suggestedPosts = post.latest_posts

    return (
        <div className={styles.postSuggestions}>
            <h2>You May Also Like...</h2>
            <div className={styles.snippets}>
                {suggestedPosts?.map(p => (
                    <Snippet post={p} />
                ))}
            </div>
        </div>
    )
}