import { useRef, useEffect } from "react"

import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Container from "../components/container/container"
import Contact from "../components/contact/contact"
import ProvidersHero from "../components/ProvidersHero/ProvidersHero"
import CotModules from "../components/CotModules/CotModules"
import CotDataSources from "../components/CotDataSources/CotDataSources"
import CotFeatures from "../components/CotFeatures/CotFeatures"
import ArrangeDemo from "../components/ArrangeDemo/ArrangeDemo"
import ProvidersBDHero from "../components/ProvidersBDHero/ProvidersBDHero"
import BDModules from "../components/BDModules/BDModules"
import BDDataSources from "../components/BDDataSources/BDDataSources"
import BDFeatures from "../components/BDFeatures/BDFeatures"

import styles from "./css/Water.module.css"

export default function Water() {
    const demo = useRef(null)
    const domestic = useRef(null)
    const business = useRef(null)

    useEffect(() => {
        document.title = 'Water | IDenteq'
    }, [])

    return (
        <Container>
            <Header page="water" />

            <ProvidersHero title="COT Portal ™" subtitle="A powerful data tool for domestic water providers" cotRef={domestic} bdRef={business}>
                <p className={styles.largeText}>Introducing the COT Portal from IDenteq – our groundbreaking SaaS data solution for the domestic water industry</p>
                <p className={styles.smallText}>Designed based on our years of experience of working in the domestic water sphere, the platform contains 8 independent modules designed to protect revenue, mitigate risk, manage debt and combat fraud.</p>
            </ProvidersHero>

            <div className={styles.domesticSolutions} ref={domestic}>
                <div className={styles.contentWrap}>
                    <div className={styles.headerWrap}>
                        <h1>Domestic Solutions</h1>
                    </div>
                    <div className={styles.imgTextWrap}>
                        <div className={styles.imgWrap}>
                            <img src="/images/COT-Portal-Logo.png" alt="COT Portal" />
                        </div>
                        <div className={styles.textWrap}>
                            <p>IDenteq’s Change of Tenancy (COT) Portal has been developed specifically to help <strong>domestic water providers</strong> with their billing and revenue assurance. With seamless data aggregation and proactive measures, our COT Portal is your automated ally in revenue protection, risk mitigation, debt management, and fraud prevention.</p>
                            <div className={styles.buttonWrap}>
                                <a href="#demo" onClick={() => demo.current.scrollIntoView({behavior: "smooth"})}>Book a Demo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.modules}>
                <div className={styles.contentWrap}>
                    <div className={styles.hasYouCovered}>
                        <h2>COT has you covered</h2>
                        <p>The COT Portal contains 8 independent modules to protect revenue and combat common frauds. Each of the modules fulfil a specific role, but they all work on the same principle: advanced data aggregation to aid domestic water providers in their revenue protection efforts.</p>
                    </div>
                    <CotModules />
                </div>
            </div>

            <CotDataSources>
                <p>All the data required to keep track of your domestic water customers is contained within the COT Portal, ensuring nothing slips through the net.</p>
            </CotDataSources>

            <CotFeatures />
            <ArrangeDemo page="water" demoRef={demo} />

            <ProvidersBDHero buttonRef={business} demoRef={demo}>
                <p>IDenteq’s Business Data (BD Portal) is a data services solution designed specifically for <strong>commercial water providers.</strong> It works by aggregating multiple sources of data and taking proactive measures to support customer management, fraud detection and revenue protection.</p>
            </ProvidersBDHero>

            <BDModules page="water" />
            <BDDataSources />
            <BDFeatures />
            <ArrangeDemo page="water" demoRef={demo} />

            <div className={styles.contactWrap} ref={demo} >
                <Contact page="water" />
            </div>
            <Footer page="water" />
        </Container> 
    )
}
