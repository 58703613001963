import React, { Component } from 'react'

import "animate.css"
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Header from './/../components/header/header'
import Footer from './/../components/footer/footer'
import Container from './/../components/container/container'
import Contact from './/../components/contact/contact'

import './css/bdportal.css';

// Box Icons
import PowerfulDataSetsIcon from '../img/icons/IDenteq_Icons_SVG-04.svg';
import DataAggregationIcon from '../img/icons/IDenteq_Icons_SVG-03.svg';
import WorkflowEnableDataIcon from '../img/icons/IDenteq_Icons_SVG-01.svg';
import SelfServiceReportingIcon from '../img/icons/IDenteq_Icons_SVG-02.svg';
import AddressAlertsIcon from '../img/icons/Address-Alerts-Icon.png';
import CompaniesHouseAlertsIcon from '../img/icons/Companies-House-Alerts-Icon.png';
import ContactValidationIcon from '../img/icons/Contact-Validation-Icon.png';
import OccupierValidationIcon from '../img/icons/Occupier-Validation-Icon.png';
import VacantCOTAlertsIcon from '../img/icons/Vacant-COT-Alerts-Icon.png';
import PhoenixAlertsIcon from '../img/icons/Phoenix-Alerts-Icon.png';
import PropertySearchIcon from '../img/icons/Property-Search-Icon.png';

// Multiple Data Sources
import RoyalMail from '../img/datasources/Royal-Mail.jpg';
import OrdnanceSurvey from '../img/datasources/Ordnance-Survey.png';
import VOA from '../img/datasources/VOA.png';
import HMLandRegistry from '../img/datasources/HM-Land-Registry.png';
import Google from '../img/datasources/Google.png';
import CompaniesHouse from '../img/datasources/Companies-House.png';
import Yell from '../img/datasources/Yell.png';
import FSA from '../img/datasources/FSA.png';
import BT from '../img/datasources/BT.png';
import TripAdvisor from '../img/datasources/Trip-Advisor.png';
import DVSA from '../img/datasources/DVSA.png';
import Experian from '../img/datasources/Experian.png';


export default class BDPortal extends Component {

    componentDidMount() {
        document.title = 'BD Portal | IDenteq'
    }

    render() {
        return (
            <div>
                <Header page="bdportal" />
                {/* BDPortal content */}
                <Container>

                    {/* Book a demo */}
                    <div id="bdportal_bookADemo" class="bdportal_row">
                        <div className="bdportal-content-wrap">
                            <div class="bdportal_header_wrap">
                                <h1>Business Data Portal ™</h1>
                            </div>

                            <div id="bdportal_bookDemo_imgtext_wrap">
                                <div id="bdportal_bookDemo_img_wrap" class="product_img_wrap">
                                    <img loading="lazy" decoding="async" class="img_product"
                                        src="/images/IDenteq_BD_Portal.png" alt="Image of bd portal" />
                                </div>

                                <div id="bdportal_bookDemo_text_wrap" class="bdportal_text_wrap">
                                    <p>Business Data Portal is our commercial data services solution for the utilities industry, which utilises multiple data sources and machine learning to support customer management, fraud detection and revenue protection.</p>
                                    <a class="button_dark" href="/bd-portal/">Book a Demo</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Book a demo - END */}

                    <div className="grey-background">
                        {/* Tailored Solution */}
                        <div id="bdportal_tailoredSolution" class="bdportal_row">

                            <div class="bdportal_header_wrap">
                                <h2>Tailored Solution</h2>
                            </div>

                            <div class="bdportal_text_wrap">
                                <p>Business Data Portal has been built to meet the challenges facing utility firms today, and the solution can be deployed in full or on a modular basis.</p>
                            </div>

                            <div id="bdportal_tailoredSolution_box_wrap">
                                {/* Data sets */}
                                <div class="bdportal_tailoredSolution_box">
                                    <div class="bdportal_tailoredSolution_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="90" height="79"
                                                src={PowerfulDataSetsIcon}
                                                alt="Icon Image for powerful data sets" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="bdportal_tailoredSolution_box_text_wrap">
                                        <p>Business Data Portal introduces innovative and powerful data sets to identify and manage COT/MiMO.</p>
                                    </div>
                                </div>
                                {/* Data sets - END */}

                                {/* Data aggregated */}
                                <div class="bdportal_tailoredSolution_box">
                                    <div class="bdportal_tailoredSolution_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="90" height="90"
                                                src={DataAggregationIcon}
                                                alt="Icon Image for identify and prevent fraud" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="bdportal_tailoredSolution_box_text_wrap">
                                        <p>Data is aggregated to identify and prevent fraud through proactive alerts.</p>
                                    </div>
                                </div>
                                {/* Data aggregated - END */}

                                {/* Enable data */}
                                <div class="bdportal_tailoredSolution_box">
                                    <div class="bdportal_tailoredSolution_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="90" height="89"
                                                src={WorkflowEnableDataIcon}
                                                alt="Icon Image for workflow enable data" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="bdportal_tailoredSolution_box_text_wrap">
                                        <p>The platform includes workflow to enable data driven processes to be managed, or can be called via APIs into existing CRM/Billing systems.</p>
                                    </div>
                                </div>
                                {/* Enable data - END */}

                                {/* Reporting analysis */}
                                <div class="bdportal_tailoredSolution_box">
                                    <div class="bdportal_tailoredSolution_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="77" height="90"
                                                src={SelfServiceReportingIcon}
                                                alt="Icon Image for Self Service Reporting" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="bdportal_tailoredSolution_box_text_wrap">
                                        <p>Business Data Portal includes self-service reporting and analysis tools, designed to support your operational processes.</p>
                                    </div>
                                </div>
                                {/* Reporting analysis - END */}
                            </div>

                        </div>
                        {/* Tailored Solution - END */}

                        {/* Multiple Data Sources */}
                        <div id="bdportal_multipleDataSources" class="bdportal_row">

                            <div class="bdportal_header_wrap">
                                <h2>Multiple Data Sources</h2>
                            </div>

                            <div class="bdportal_text_wrap">
                                <p>All the data required to tackle common frauds is contained within the COT Portal, making it simple and efficient to use data to prevent revenue loss.</p>
                            </div>

                            <div class="bdportal_multipleDataSources_icons_wrap">
                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={RoyalMail}
                                        alt="Icon Royal Mail" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={OrdnanceSurvey}
                                        alt="Icon Ordinance Survey" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={VOA}
                                        alt="Icon Valuation Office Agency" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={HMLandRegistry}
                                        alt="Icon HM Land Registry" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={Google}
                                        alt="Icon Google" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={CompaniesHouse}
                                        alt="Icon Companies House" />
                                </div>

                            </div>

                            <div class="bdportal_multipleDataSources_icons_wrap" id="bdportal-mds-icons-last-row">

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={Yell}
                                        alt="Icon Yell" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={FSA}
                                        alt="Icon FSA" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={BT}
                                        alt="Icon BT" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={TripAdvisor}
                                        alt="Icon Trip Advisor" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={DVSA}
                                        alt="Icon DVSA" />
                                </div>

                                <div class="bdportal_multipleDataSources_icon_wrap">
                                    <img loading="lazy" decoding="async" 
                                        src={Experian}
                                        alt="Icon Experian" />
                                </div>
                            </div>

                        </div>
                        {/* Multiple Data Sources - END */}

                        {/* Tailored Content */}
                        <div id="bdportal_tailoredContent" class="bdportal_row">

                            <div class="bdportal_header_wrap">
                                <h2>Tailored Content</h2>
                            </div>

                            <div class="bdportal_text_wrap">
                                <p>Business Data Portal has been built to meet the challenges facing utility firms today, and the solution can be deployed in full or on a modular basis.</p>
                            </div>

                            <div className="bdportal_tailoredContent_box_wrap">
                                {/* Address Alerts */}
                                <div class="bdportal_tailoredContent_box">
                                    <a href="/modules/bd-address-alerts">
                                        <div class="bdportal_tailoredContent_box_image_wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="79"
                                                    src={AddressAlertsIcon}
                                                    alt="Icon Address Alters" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="bdportal_tailoredContent_box_text_wrap">
                                            <h4 class="bdportal_tailoredContent_box_text_header">
                                                Address Alerts
                                            </h4>
                                            <p>Notification of property changes including change of use</p>
                                        </div>
                                    </a>
                                </div>
                                {/* Address Alerts - END */}

                                {/* Companies House Alerts */}
                                <div class="bdportal_tailoredContent_box">
                                    <a href="/modules/bd-companies-house-alerts">
                                        <div class="bdportal_tailoredContent_box_image_wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="79"
                                                    src={CompaniesHouseAlertsIcon}
                                                    alt="Icon Companies House Alters" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="bdportal_tailoredContent_box_text_wrap">
                                            <h4 class="bdportal_tailoredContent_box_text_header">
                                                Companies House Alerts
                                            </h4>
                                            <p>Notification of company updates from Companies House</p>
                                        </div>
                                    </a>
                                </div>
                                {/* Companies House Alerts - END */}

                                {/* Contact Validation */}
                                <div class="bdportal_tailoredContent_box">
                                    <a href="/modules/bd-contact-validation">
                                        <div class="bdportal_tailoredContent_box_image_wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="79"
                                                    src={ContactValidationIcon}
                                                    alt="Icon Contact Validation" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="bdportal_tailoredContent_box_text_wrap">
                                            <h4 class="bdportal_tailoredContent_box_text_header">
                                                Contact Validation
                                            </h4>
                                            <p>Validate & append telephone numbers from multiple data sources</p>
                                        </div>
                                    </a>
                                </div>
                                {/* Contact Validation - END */}

                                {/* Occupier Validation */}
                                <div class="bdportal_tailoredContent_box">
                                    <a href="/modules/bd-occupier-validation">
                                        <div class="bdportal_tailoredContent_box_image_wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="79"
                                                    src={OccupierValidationIcon}
                                                    alt="Icon Occupier Validation" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="bdportal_tailoredContent_box_text_wrap">
                                            <h4 class="bdportal_tailoredContent_box_text_header">
                                                Occupier Validation
                                            </h4>
                                            <p>Validation that a company is trading from the supply premises</p>
                                        </div>
                                    </a>
                                </div>
                                {/* Occupier Validation - END */}
                            </div>
                            <div className="bdportal_tailoredContent_box_wrap" id="bdportal-tc-last-row">
                                {/* Vacant COT Alerts */}
                                <div class="bdportal_tailoredContent_box">
                                    <a href="/modules/bd-vacant-cot-alerts">
                                        <div class="bdportal_tailoredContent_box_image_wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="79"
                                                    src={VacantCOTAlertsIcon}
                                                    alt="Icon Vacant COT Alerts" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="bdportal_tailoredContent_box_text_wrap">
                                            <h4 class="bdportal_tailoredContent_box_text_header">
                                                Vacant COT Alerts
                                            </h4>
                                            <p>Notification of new occupiers for vacant properties</p>
                                        </div>
                                    </a>
                                </div>
                                {/* Vacant COT Alerts - END */}

                                {/* Phoenix Alerts */}
                                <div class="bdportal_tailoredContent_box">
                                    <a href="/modules/bd-phoenix-alerts">
                                        <div class="bdportal_tailoredContent_box_image_wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="79"
                                                    src={PhoenixAlertsIcon}
                                                    alt="Icon Phoenix Alters" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="bdportal_tailoredContent_box_text_wrap">
                                            <h4 class="bdportal_tailoredContent_box_text_header">
                                                Phoenix Alerts
                                            </h4>
                                            <p>Notification of potential phoenix companies & directors</p>
                                        </div>
                                    </a>
                                </div>
                                {/* Phoenix Alerts - END */}

                                {/* Real Time Validation */}
                                <div class="bdportal_tailoredContent_box">
                                    <a href="/modules/bd-realtime-validation">
                                        <div class="bdportal_tailoredContent_box_image_wrap">
                                            <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="79"
                                                    src={PropertySearchIcon}
                                                    alt="Icon Real Time Validation" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="bdportal_tailoredContent_box_text_wrap">
                                            <h4 class="bdportal_tailoredContent_box_text_header">
                                                Real Time Validation
                                            </h4>
                                            <p>Real-time property searches to identify occupiers</p>
                                        </div>
                                    </a>
                                </div>
                                {/* Real Time Validation - END */}
                            </div>

                        </div>
                        {/* Tailored Content - END */}
                    </div>

                </Container>
                {/* BDPortal content - END */}
                <div className="contact-wrap">
                    <Contact page="bdportal" />
                </div>
                <Footer page="bdportal" />
            </div>
        );
    }
}
