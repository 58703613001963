import React, { useState, useEffect, useRef } from 'react'
import { NavLink, Link, withRouter } from 'react-router-dom';
import './header.scss';
import Logo from '../../img/Identeq-logo-header.png';
import Container from '../container/container'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ExpandMore } from '@mui/icons-material';
import { Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { useMediaQuery } from 'react-responsive';

import { slide as Menu } from 'react-burger-menu'

export default function Header({ page }) {
    const [lastScrollTop, setLastScrollTop] = useState(0)
    const header = useRef(null)
    const placeholder = useRef(null)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1280px)' })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1281px)' })

    // header, placeholder are both React.createRef()

    useEffect(() => {
        window.addEventListener('scroll', headerScroll, true);

        let currentPage = page;
        let currentNav = document.getElementById(currentPage);
        if (currentNav) currentNav.style.color = "#33d1ad"
    })

    const headerScroll = () => {
        let scrollTop = window.scrollY || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
            if (!header.current?.classList.contains('scroll_down')) {
                header.current?.classList.remove('scroll_up');
                header.current?.classList.add('scroll_down');
                placeholder.current?.classList.add('placeholder_header');
            }
        } else {
            if (scrollTop === 0) {
                header.current?.classList.remove('scroll_down');
                header.current?.classList.add('scroll_up');
                placeholder.current?.classList.remove('placeholder_header');
            }
        }

        setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop)
    }

    return (
        <Container>
            {/* <!-- Header --> */}
            <header id="header" class="scroll_up" ref={header}>
                <div class="header_wrap">

                    {/* <!-- Header logo --> */}
                    <div id="header_logo_wrap">
                        <a href="/">
                            <img id='img_header_logo' src={Logo} alt="IDenteq" />
                        </a>
                    </div>
                    {/* <!-- Header logo - END --> */}

                    {/* <!-- Header menu --> */}
                    <div id="header_nav_wrap">
                        {isDesktopOrLaptop && <nav id="nav_header_menu">
                            <ul id="ul_header_menu">
                                <li class="li_header_menu">
                                    <Stack direction="row" alignItems="center" className="menu-item" id="home">
                                        <a href="/" aria-current="page">Home </a>
                                        <span class="menu_icon"><ExpandMore fontSize="small" /></span>
                                    </Stack>
                                    <ul class="sub-menu">
                                        <li class="li_header_menu" id="publications" className="menu-item">
                                            <a href="/publications">Publications</a>
                                        </li>
                                        <li class="li_header_menu" id="careers" className="menu-item">
                                            <a href="/careers">Careers</a>
                                        </li>
                                        <li class="li_header_menu" id="technology" className="menu-item">
                                            <a href="/technology">Technology</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="li_header_menu nav_nolink">
                                    <Stack direction="row" alignItems="center" id="software" className="menu-item">
                                        <a>Software </a>
                                        <span class="menu_icon"><ExpandMore fontSize="small" /></span>
                                    </Stack>
                                    <ul class="sub-menu">
                                        <li class="li_header_menu" id="cotportal" className="menu-item">
                                            <a href="/cot-portal">COT Portal</a>
                                        </li>
                                        <li class="li_header_menu" id="bdportal" className="menu-item">
                                            <a href="/bd-portal">BD Portal</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="li_header_menu nav_nolink">
                                    <Stack direction="row" alignItems="center" id="services" className="menu-item">
                                        <a>Services </a>
                                        <span class="menu_icon"><ExpandMore fontSize="small" /></span>
                                    </Stack>
                                    <ul class="sub-menu">
                                        <li class="li_header_menu" id="pmaas" className="menu-item">
                                            <a href="/pmaas">PMaaS</a>
                                        </li>

                                        <li class="li_header_menu" id="changeofuse" className="menu-item">
                                            <a href="/change-of-use">Change of Use</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="li_header_menu" id="testimonials" className="menu-item">
                                    <a href="/testimonials">Testimonials</a>
                                </li>
                                <li class="li_header_menu" id="contact" className="menu-item">
                                    <a href="/contact">Contact</a>
                                </li>
                            </ul>
                        </nav>}
                        {isTabletOrMobile && <Menu className='header_mobile_menu' right>
                            <ul>
                                <li>
                                    <a href="/" id='home' aria-current="page">Home </a>
                                    <ul>
                                        <li>
                                            <a href="/publications" id='publications'>Publications</a>
                                        </li>
                                        <li>
                                            <a href="/careers" id='careers'>Careers</a>
                                        </li>
                                        <li>
                                            <a href="/technology" id='technology'>Technology</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a>Software </a>
                                    <ul>
                                        <li>
                                            <a href="/cot-portal" id='cotportal'>COT Portal</a>
                                        </li>
                                        <li>
                                            <a href="/bd-portal" id='bdportal'>BD Portal</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a>Services </a>
                                    <ul>
                                        <li>
                                            <a href="/pmaas" id='pmaas'>PMaaS</a>
                                        </li>

                                        <li>
                                            <a href="/change-of-use" id='changeofuse'>Change of Use</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="/testimonials" id='testimonials'>Testimonials</a>
                                </li>
                                <li>
                                    <a href="/contact" id='contact'>Contact</a>
                                </li>
                            </ul>
                        </Menu>}
                    </div>
                    {/* <!-- Header menu - END --> */}

                </div>
            </header>
            <div ref={placeholder} />
            {/* <!-- Header - END --> */}
        </Container>
    );
}