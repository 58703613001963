import styles from "./ResponsivePlayer.module.css"

import ReactPlayer from "react-player/file"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

export default function ResponsivePlayer({url, thumbnail}) {
    return (
        <div className={styles.playerWrapper}>
            <ReactPlayer
                className={styles.reactPlayer}
                url={url}
                width='100%'
                height='100%'
                playing
                controls
                light={thumbnail}
                playIcon={<PlayCircleOutlineIcon style={{ fontSize: '120px', color: 'white' }} />}
            />
        </div>
    )
}