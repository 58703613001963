import styles from "./CotModules.module.css"

import "animate.css"
import { AnimationOnScroll } from "react-animation-on-scroll"

// 8 Independent Modules
import COTValidationIcon from '../../img/icons/COT-Validation-Icon.svg';
import TariffValidationIcon from '../../img/icons/Tariff-Validation-Icon.svg';
import COTFraudAlerts from '../../img/icons/COT-Fraud-Alerts-Icon.svg';
import AddressAlerts from '../../img/icons/Address-Alerts-Icon.svg';
import ContactValidationIcon from '../../img/icons/Contact-Validation-Icon.svg';
import OwnerValidationIcon from '../../img/icons/Owner-Validation-Icon.svg';
import OccupiedCOTAlertsIcon from '../../img/icons/Occupied-COT-Alerts-Icon.svg';
import VoidCOTAlertsIcon from '../../img/icons/Void-COT-Alerts-Icon.svg';

export default function CotModules() {
    return (
        <div className={styles.modules}>
            <p id={styles.twoTypes}><strong>There are two types of service:</strong></p>
            <div className={styles.serviceTypes}>
                <p><strong>Validation services</strong> take in data from a utility supplier in order to confirm the accuracy of the information received, and highlight potential fraud.</p>
                <p><strong>Alert services</strong> proactively monitor utility supplier’s properties to identify fraud based on customer actions or address information changes.</p>
            </div>

            <div className={styles.boxesWrap}>

                {/* Cleanse & Validation */}
                <div className={styles.moduleBox} id={styles.cotGreen}>
                    <a href="https://www.identeq.co.uk/data-cleanse-and-validation/">
                        <div className={styles.boxImgWrap} >
                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                <img loading="lazy" decoding="async" width="90" height="90"
                                    src={COTValidationIcon}
                                    alt="Icon Image for COT Validation" />
                            </AnimationOnScroll>
                        </div>

                        <div className={styles.boxTextWrap} >
                            <h4>Cleanse &amp; Validation</h4>
                        </div>
                    </a>
                </div>
                {/* Cleanse & Validation - END */}

                {/* Tariff Validation */}
                <div className={styles.moduleBox} id={styles.cotGreen}>
                    <a href="https://www.identeq.co.uk/tariff-validation/">
                        <div className={styles.boxImgWrap} >
                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                <img loading="lazy" decoding="async" width="90" height="90"
                                    src={TariffValidationIcon}
                                    alt="Icon Image for Tariff Validation" />
                            </AnimationOnScroll>
                        </div>

                        <div className={styles.boxTextWrap} >
                            <h4>Tariff Validation</h4>
                        </div>
                    </a>
                </div>
                {/* Tariff Validation - END */}

                {/* COT Fraud Alerts */}
                <div className={styles.moduleBox} id={styles.cotBlack}>
                    <a href="https://www.identeq.co.uk/cot-fraud-alerts/">
                        <div className={styles.boxImgWrap} >
                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                <img loading="lazy" decoding="async" width="90" height="90"
                                    src={COTFraudAlerts}
                                    alt="Icon Image for COT Fraud Alerts" />
                            </AnimationOnScroll>
                        </div>

                        <div className={styles.boxTextWrap} >
                            <h4>COT Fraud Alerts</h4>
                        </div>
                    </a>
                </div>
                {/* COT Fraud Alerts - END */}

                {/* Address Alerts */}
                <div className={styles.moduleBox} id={styles.cotBlack}>
                    <a href="https://www.identeq.co.uk/cot-address-alerts/">
                        <div className={styles.boxImgWrap} >
                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                <img loading="lazy" decoding="async" width="90" height="90"
                                    src={AddressAlerts}
                                    alt="Icon Image for Address Alerts" />
                            </AnimationOnScroll>
                        </div>

                        <div className={styles.boxTextWrap} >
                            <h4>Address Alerts</h4>
                        </div>
                    </a>
                </div>
                {/* Address Alerts - END */}
            </div>
            <div className={styles.boxesWrap} >
                {/* Contact Validation */}
                <div className={styles.moduleBox} id={styles.cotGreen}>
                    <a href="https://www.identeq.co.uk/contact-validation/">
                        <div className={styles.boxImgWrap} >
                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                <img loading="lazy" decoding="async" width="90" height="90"
                                    src={ContactValidationIcon}
                                    alt="Icon Image for Contact Validation" />
                            </AnimationOnScroll>
                        </div>

                        <div className={styles.boxTextWrap} >
                            <h4>Contact Validation</h4>
                        </div>
                    </a>
                </div>
                {/* Contact Validation - END */}

                {/* Owner Validation */}
                <div className={styles.moduleBox} id={styles.cotGreen}>
                    <a href="https://www.identeq.co.uk/owner-validation/">
                        <div className={styles.boxImgWrap} >
                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                <img loading="lazy" decoding="async" width="90" height="90"
                                    src={OwnerValidationIcon}
                                    alt="Icon Image for Owner Validation" />
                            </AnimationOnScroll>
                        </div>

                        <div className={styles.boxTextWrap} >
                            <h4>Owner Validation</h4>
                        </div>
                    </a>
                </div>
                {/* Owner Validation - END */}

                {/* Gone Away Alerts */}
                <div className={styles.moduleBox} id={styles.cotBlack}>
                    <a href="https://www.identeq.co.uk/gone-away-alerts/">
                        <div className={styles.boxImgWrap} >
                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                <img loading="lazy" decoding="async" width="90" height="90"
                                    src={OccupiedCOTAlertsIcon}
                                    alt="Icon Image for Gone Away Alerts" />
                            </AnimationOnScroll>
                        </div>

                        <div className={styles.boxTextWrap} >
                            <h4>Gone Away Alerts</h4>
                        </div>
                    </a>
                </div>
                {/* Gone Away Alerts - END */}

                {/* Void COT Alerts */}
                <div className={styles.moduleBox} id={styles.cotBlack}>
                    <a href="https://www.identeq.co.uk/void-cot-alerts/">
                        <div className={styles.boxImgWrap} >
                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                <img loading="lazy" decoding="async" width="90" height="90"
                                    src={VoidCOTAlertsIcon}
                                    alt="Icon Image for Void COT Alerts" />
                            </AnimationOnScroll>
                        </div>

                        <div className={styles.boxTextWrap} >
                            <h4>Void COT Alerts</h4>
                        </div>
                    </a>
                </div>
                {/* Void COT Alerts - END */}
            </div>
        </div>
    )
}