import styles from "./ProvidersHero.module.css"

export default function ProvidersHero({title, subtitle, cotRef, bdRef, children}) {
    return (
        <div className={styles.heroSection}>
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h1>
                        {title}
                        <b>{title ? <br /> : <></>}{subtitle}</b>
                        <p>&nbsp;</p>
                    </h1>
                </div> 
                <div className={styles.imgTextWrap}>
                    <div className={styles.imgWrap}>
                        <img src="https://i.vimeocdn.com/video/1693167182-efa0d7d2a9d61c819552508bcc766e0ec5e73841ebb701d0fc9b90b4214cdb65-d?mw=600&mh=338" alt="COT Portal" />
                    </div>
                    <div className={styles.textWrap}>
                        {children}
                        <div className={styles.buttonWrap} id={styles.firstButton}>
                            <a href="#domestic" onClick={() => cotRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})}>Domestic Solutions</a>
                        </div>
                        <div className={styles.buttonWrap}>
                            <a href="#business" onClick={() => bdRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})}>Business Solutions</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
