import React, { Component } from 'react'

import "animate.css"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ReactPlayer from "react-player/file"

import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import Contact from '../components/contact/contact'

import './css/cotportal.css';

import COTPortalMockup from '../img/cot-portal-mockup.png';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

// How it Works
import FraudPreventionIcon from '../img/icons/Fraud-prevention-Icon.svg';
import MultipleDataSourcesIcon from '../img/icons/Multiple-Data-Sources-Icon.svg';
import ProactiveMonitoringIcon from '../img/icons/Proactive-Monitoring-Icon.svg';
import AnalysisIcon from '../img/icons/Analysis-Icon.svg';

// Multiple Data Sources
import Experian from '../img/datasources/Experian.png';
import HMLandRegistry from '../img/datasources/HM-Land-Registry.png';
import RoyalMail from '../img/datasources/Royal-Mail.jpg';
import OrdnanceSurvey from '../img/datasources/Ordnance-Survey.png';
import DataOnDemand from '../img/datasources/Data-on-Demand.png';
import BT from '../img/datasources/BT.png';
import Propalt from '../img/datasources/Propalt.png';
import Re_mover from '../img/datasources/Re-mover.png'
import VRS from '../img/datasources/VRS.png';
import Mortascreen from '../img/datasources/Mortascreen.png';
import NFI from '../img/datasources/NFI.png';
import GoogleMaps from '../img/datasources/Google-Maps.png';
import GovUK from '../img/datasources/Gov-UK.png';
import Zoopla from '../img/datasources/Zoopla.png';

// 8 Independent Modules
import COTValidationIcon from '../img/icons/COT-Validation-Icon.svg';
import TariffValidationIcon from '../img/icons/Tariff-Validation-Icon.svg';
import COTFraudAlerts from '../img/icons/COT-Fraud-Alerts-Icon.svg';
import AddressAlerts from '../img/icons/Address-Alerts-Icon.svg';
import ContactValidationIcon from '../img/icons/Contact-Validation-Icon.svg';
import OwnerValidationIcon from '../img/icons/Owner-Validation-Icon.svg';
import OccupiedCOTAlertsIcon from '../img/icons/Occupied-COT-Alerts-Icon.svg';
import VoidCOTAlertsIcon from '../img/icons/Void-COT-Alerts-Icon.svg';


export default class CotPortal extends Component {

    componentDidMount() {
        document.title = 'COT Portal | IDenteq'
    }

    render() {
        return (
            <Container>
                <Header page="cotportal" />
                <Container>
                    {/* CotPortal content */}

                    {/* Book a demo */}
                    <div id="cotPortal_bookADemo" class="cotPortal_row">
                        <div className="content-wrap">
                            <div class="header-wrap">
                                <h1>COT Portal ™</h1>
                            </div>

                            <div id="cotPortal_bookDemo_body_wrap">
                                <div id="cotPortal_bookDemo_img_wrap" class="product_img_wrap">
                                    <div className="video-container">
                                        <ReactPlayer url="https://cotportal-videos-live.s3.eu-west-2.amazonaws.com/identeq-cot-portal.mp4" playing controls width="100%" height="100%" light={COTPortalMockup} playIcon={<PlayCircleOutlineIcon style={{fontSize:'120px', color: 'white'}} />} />
                                    </div>
                                </div>

                                <div id="cotPortal_bookDemo_text_wrap" class="product_text_wrap">
                                    <p className="large-text">COT Portal is our SaaS fraud solution for the domestic utilities industry, containing 8 independent modules designed to protect revenue and combat common frauds.</p>
                                    <p className="small-text">The platform has been designed based on our experience of working in the domestic energy and water industries, to drive innovation through the use of new technology.</p>
                                    <div id="cotPortal_bookDemo_btn_wrap">
                                        <a id="cotPortal_bookDemo_btn"
                                            class="button_dark"
                                            href="/contact">Book a Demo</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* Book a demo - END */}

                    {/* How it works */}
                    <div id="cotPortal_howItWorks" class="cotPortal_row">
                        <div className="content-wrap">
                            <div class="header-wrap">
                                <h2>How it Works</h2>
                            </div>

                            <div class="howitworks-text-wrap">
                                <p>COT Portal is a <strong>fraud prevention platform</strong> which brings together <strong>multiple data sources</strong> to identify and act on customer fraud.</p>
                                <br />
                                <p>The platform uses automation and <strong>proactive monitoring</strong> to identify fraudulent account activity, and provides <strong>real-time analysis</strong> on events.</p>
                            </div>

                            <div class="cotPortal_box_wrap">
                                {/* Fraud Prevention */}
                                <div class="cotPortal_box" id="cot-green">
                                    <div class="cotPortal_box_image_wrap">
                                        <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="90" height="90"
                                                src={FraudPreventionIcon}
                                                alt="Icon Image for fraud prevention" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="cotPortal_box_text_wrap">
                                        <p>As a <strong>fraud prevention</strong> platform, COT Portal introduces the ability to use powerful data sets to identify and manage fraudulent Change of Tenancies.</p>
                                    </div>
                                </div>
                                {/* Fraud Prevention - END */}

                                {/* Multiple Data Sources */}
                                <div class="cotPortal_box" id="cot-green">
                                    <div class="cotPortal_box_image_wrap">
                                        <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="90" height="90"
                                                src={MultipleDataSourcesIcon}
                                                alt="Icon Image for multiple data sources" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="cotPortal_box_text_wrap">
                                        <p>Data to prevent fraud in utilities is available from <strong>multiple data sources</strong>, COT Portal brings them together.</p>
                                    </div>
                                </div>
                                {/* Multiple Data Sources - END */}

                                {/* Proactive Monitoring */}
                                <div class="cotPortal_box" id="cot-green">
                                    <div class="cotPortal_box_image_wrap">
                                        <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="90" height="90"
                                                src={ProactiveMonitoringIcon}
                                                alt="Icon Image for proactive monitoring" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="cotPortal_box_text_wrap">
                                        <p>COT Portal uses <strong>proactive monitoring</strong> to identify fraud quickly and minimise the losses incurred.</p>
                                    </div>
                                </div>
                                {/* Proactive Monitoring - END */}

                                {/* Analysis */}
                                <div class="cotPortal_box" id="cot-green">
                                    <div class="cotPortal_box_image_wrap">
                                        <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                            <img loading="lazy" decoding="async" width="90" height="90"
                                                src={AnalysisIcon}
                                                alt="Icon Image for analysis" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="cotPortal_box_text_wrap">
                                        <p>COT Portal includes self-service reporting and <strong>analysis</strong> tools, designed to support benefit tracking and opportunity assessments.</p>
                                    </div>
                                </div>
                                {/* Analysis - END */}
                            </div>
                        </div>

                    </div>
                    {/* How it works - END */}

                    {/* Multiple Data Sources */}
                    <div id="cotPortal_multipleDataSources" class="cotPortal_row">
                        <div className="content-wrap">
                            <div class="header-wrap">
                                <h2>Multiple Data Sources</h2>
                            </div>

                            <div class="cotPortal_text_wrap">
                                <p>All the data required to tackle common frauds is contained within the COT Portal, making it simple and efficient to use data to prevent revenue loss.</p>
                            </div>

                            <div className="MDS-logos">
                                <div class="cotPortal_multipleDataSources_row">
                                    <img loading="lazy" decoding="async"
                                        src={Experian}
                                        alt="Experian" />
                                    <img loading="lazy" decoding="async"
                                        src={HMLandRegistry}
                                        alt="HM Land Registry" />
                                    <img loading="lazy" decoding="async"
                                        src={RoyalMail}
                                        alt="Royal Mail" />
                                    <img loading="lazy" decoding="async"
                                        src={OrdnanceSurvey}
                                        alt="Ordnance Survey" />
                                    <img loading="lazy" decoding="async"
                                        src={DataOnDemand}
                                        alt="Data on Demand" />
                                    <img loading="lazy" decoding="async"
                                        src={BT}
                                        alt="BT" />
                                </div>

                                <div class="cotPortal_multipleDataSources_row">
                                    <img loading="lazy" decoding="async"
                                        src={Propalt}
                                        alt="Propalt" />
                                    <img loading="lazy" decoding="async"
                                        src={Re_mover}
                                        alt="Re-mover" />
                                    <img loading="lazy" decoding="async"
                                        src={VRS}
                                        alt="VRS" />
                                    <img loading="lazy" decoding="async"
                                        src={Mortascreen}
                                        alt="Mortascreen" />
                                    <img loading="lazy" decoding="async"
                                        src={NFI}
                                        alt="NFI" />
                                    <img loading="lazy" decoding="async"
                                        src={GoogleMaps}
                                        alt="Google Maps" />
                                </div>
                                <div className="cotPortal_multipleDataSources_row MDS-last-row">
                                    <img loading="lazy" decoding="async"
                                        src={GovUK}
                                        alt="Gov UK" />
                                    <img loading="lazy" decoding="async"
                                        src={Zoopla}
                                        alt="Zoopla" />
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Multiple Data Sources */}

                    {/* 8 Independent Modules */}
                    <div id="cotPortal_modules" class="cotPortal_row">
                        <div className="content-wrap">
                            <div class="header-wrap">
                                <h2>8 Independent Modules</h2>
                            </div>

                            <div class="cotPortal_text_wrap">
                                <p>COT Portal contains 8 services which can be used together or individually.</p>
                                <p><strong>There are two types of service:</strong></p>
                                <div id="cotPortal_text_services_wrap">
                                    <div>
                                        <p><strong>Validation services</strong><span> take in data from a utility supplier in order to confirm the accuracy of the information received, and highlight potential fraud.</span></p>
                                    </div>
                                    <div>
                                        <p><strong>Alert services</strong><span> proactively monitor utility supplier’s properties to identify fraud based on customer actions or address information changes.</span></p>
                                    </div>
                                </div>
                            </div>

                            <div class="cotPortal_box_wrap">

                                {/* Cleanse & Validation */}
                                <div class="cotPortal_box" id="cot-green">
                                    <a href="/modules/cp-data-cleanse-and-validation">
                                        <div class="cotPortal_box_image_wrap">
                                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="90"
                                                    src={COTValidationIcon}
                                                    alt="Icon Image for COT Validation" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="cotPortal_box_text_wrap">
                                            <h4>Cleanse &amp; Validation</h4>
                                        </div>
                                    </a>
                                </div>
                                {/* Cleanse & Validation - END */}

                                {/* Tariff Validation */}
                                <div class="cotPortal_box" id="cot-green">
                                    <a href="/modules/cp-tariff-validation">
                                        <div class="cotPortal_box_image_wrap">
                                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="90"
                                                    src={TariffValidationIcon}
                                                    alt="Icon Image for Tariff Validation" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="cotPortal_box_text_wrap">
                                            <h4>Tariff Validation</h4>
                                        </div>
                                    </a>
                                </div>
                                {/* Tariff Validation - END */}

                                {/* COT Fraud Alerts */}
                                <div class="cotPortal_box" id="cot-black">
                                    <a href="/modules/cp-cot-fraud-alerts">
                                        <div class="cotPortal_box_image_wrap">
                                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="90"
                                                    src={COTFraudAlerts}
                                                    alt="Icon Image for COT Fraud Alerts" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="cotPortal_box_text_wrap">
                                            <h4>COT Fraud Alerts</h4>
                                        </div>
                                    </a>
                                </div>
                                {/* COT Fraud Alerts - END */}

                                {/* Address Alerts */}
                                <div class="cotPortal_box" id="cot-black">
                                <a href="/modules/cp-cot-address-alerts">
                                        <div class="cotPortal_box_image_wrap">
                                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="90"
                                                    src={AddressAlerts}
                                                    alt="Icon Image for Address Alerts" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="cotPortal_box_text_wrap">
                                            <h4>Address Alerts</h4>
                                        </div>
                                    </a>
                                </div>
                                {/* Address Alerts - END */}
                            </div>
                            <div class="cotPortal_box_wrap">
                                {/* Contact Validation */}
                                <div class="cotPortal_box" id="cot-green">
                                <a href="/modules/cp-contact-validation">
                                        <div class="cotPortal_box_image_wrap">
                                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="90"
                                                    src={ContactValidationIcon}
                                                    alt="Icon Image for Contact Validation" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="cotPortal_box_text_wrap">
                                            <h4>Contact Validation</h4>
                                        </div>
                                    </a>
                                </div>
                                {/* Contact Validation - END */}

                                {/* Owner Validation */}
                                <div class="cotPortal_box" id="cot-green">
                                    <a href="/modules/cp-owner-validation">
                                        <div class="cotPortal_box_image_wrap">
                                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="90"
                                                    src={OwnerValidationIcon}
                                                    alt="Icon Image for Owner Validation" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="cotPortal_box_text_wrap">
                                            <h4>Owner Validation</h4>
                                        </div>
                                    </a>
                                </div>
                                {/* Owner Validation - END */}

                                {/* Gone Away Alerts */}
                                <div class="cotPortal_box" id="cot-black">
                                    <a href="/modules/cp-gone-away-alerts">
                                        <div class="cotPortal_box_image_wrap">
                                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="90"
                                                    src={OccupiedCOTAlertsIcon}
                                                    alt="Icon Image for Gone Away Alerts" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="cotPortal_box_text_wrap">
                                            <h4>Gone Away Alerts</h4>
                                        </div>
                                    </a>
                                </div>
                                {/* Gone Away Alerts - END */}

                                {/* Void COT Alerts */}
                                <div class="cotPortal_box" id="cot-black">
                                    <a href="/modules/cp-void-cot-alerts">
                                        <div class="cotPortal_box_image_wrap">
                                            <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                                <img loading="lazy" decoding="async" width="90" height="90"
                                                    src={VoidCOTAlertsIcon}
                                                    alt="Icon Image for Void COT Alerts" />
                                            </AnimationOnScroll>
                                        </div>

                                        <div class="cotPortal_box_text_wrap">
                                            <h4>Void COT Alerts</h4>
                                        </div>
                                    </a>
                                </div>
                                {/* Void COT Alerts - END */}
                            </div>
                        </div>

                    </div>
                    {/* 8 Independent Models - END */}

                    {/* CotPortal content - END */}
                </Container>
                <div className="contact-wrap">
                    <Contact page="cotportal" />
                </div>
                <Footer page="cotportal" />
            </Container>
        );
    }
}
