import React from 'react'
import Container from '../container/container'
import './footer.scss';

// import IdenteqLogoFooter from '../../img/Identeq-Logo-Footer.png';
import IdenteqIconsFooter from '../../img/Identeq-Icons-Footer-new-3.png';
import Logo from '../../img/Identeq-logo-header.png';

import { LinkedIn } from '@mui/icons-material';

export default function Footer({ page }) {

    const currentYear = new Date().getFullYear();

    return (
        <Container>
            {/* <!-- Footer --> */}
            <footer>
                <div class="footer_wrap">

                    <div id="footer_logo_icons_wrap">
                        {/* <!-- Identeq Logo --> */}
                        <div id="footer_logo_wrap">
                            <img id="img_footer_logo"
                                loading="lazy" decoding="async" width="2880" height="1029"
                                src={Logo}
                                alt="Logo Image for IDenteq" />
                        </div>
                        {/* <!-- Identeq Logo - END --> */}

                        {/* <!-- Identeq Icons --> */}
                        <div id="footer_icons_wrap">
                            <img id="img_footer_icons"
                                loading="lazy" decoding="async" width="2880" height="339"
                                src={IdenteqIconsFooter}
                                alt="Image of Identeq Icons" />
                        </div>
                        {/* <!-- Identeq Icons - END --> */}
                    </div>

                    {/* <!-- Footer menu --> */}
                    <div id="footer_nav_wrap">
                        <nav id="footer_nav_menu">
                            <ul class="footer_ul_menu">
                                <li id="footer_li_home" class="footer_li_menu">
                                    <a href="/" aria-current="page" className={page == "home" ? "active" : "inactive"}>Home</a>
                                </li>
                                <li class="footer_li_menu">
                                    <a href="/cot-portal/" className={page == "cot-portal" ? "active" : "inactive"}>COT Portal</a>
                                </li>
                                <li class="footer_li_menu">
                                    <a href="/bd-portal/" className={page == "bd-portal" ? "active" : "inactive"}>BD Portal</a>
                                </li>
                                <li class="footer_li_menu">
                                    <a href="/pmaas/" className={page == "pmaas" ? "active" : "inactive"}>PMaaS</a>
                                </li>
                                <li class="footer_li_menu">
                                    <a href="/technology/" className={page == "technology" ? "active" : "inactive"}>Technology</a>
                                </li>
                                <li class="footer_li_menu">
                                    <a href="/careers/" className={page == "careers" ? "active" : "inactive"}>Careers</a>
                                </li>
                                <li class="footer_li_menu">
                                    <a href="/contact/" className={page == "contactus" ? "active" : "inactive"}>Contact</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {/* <!-- Footer menu - END --> */}

                    <div id="footer_privacyFollowUs_wrap">
                        {/* <!-- Privacy --> */}
                        <div id="footer_privacy_wrap">
                            <p><a href="/privacy-policy">Privacy Policy</a></p>
                        </div>
                        {/* <!-- Privacy - END --> */}

                        {/* <!-- Follow Us --> */}
                        <div class="footer_followUs_wrap">
                            <h4>Follow Us</h4>
                            <ul id="footer_ul_socials">
                                <li class="footer_li_socials social-linkedin">
                                    <a href="https://www.linkedin.com/company/identeq-ltd/" title="Follow on LinkedIn" target="_blank"><LinkedIn /></a>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- Follow Us - END --> */}
                    </div>

                </div>

                {/* Footer Ltd */}
                <div id="footerLtd_wrap">
                    <p>© IDenteq Ltd {currentYear} | All Rights Reserved | <a href="/privacy-policy">Privacy Policy</a></p>
                </div>

            </footer>
            {/* <!-- Footer - END --> */}
        </Container>
    );
}