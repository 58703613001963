import React, { Component } from 'react'

import "animate.css"
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Header from './/../components/header/header'
import Footer from './/../components/footer/footer'
import Container from './/../components/container/container'
import Contact from './/../components/contact/contact'

import './css/pmaas.css';
import PMaaSMeeting from '../img/pmaas-meeting.png';

// PMaaS Services
import End2EndIcon from '../img/pmaas/End-2-end-managed-project-Icon.png'
import ExperiencedResourcesIcon from '../img/pmaas/Experienced-resources-Icon.png'
import ProjectManagementOfficeIcon from '../img/pmaas/Project-management-office-Icon.png'
import ProjectReportingIcon from '../img/pmaas/Project-reporting-Icon.png'

export default class PMaaS extends Component {

    componentDidMount() {
        document.title = 'PMaaS | IDenteq'
    }

    render() {
        return (
            <Container>
                <Header page="pmaas" />
                <Container>
                    {/* PMaaS content */}

                    {/* Book a demo */}
                    <div id="pmaas_bookademo_wrap">
                        <div className="pmaas-content-wrap">
                            <div id="pmaas_bookademo_h1_wrap">
                                <h1>PMaaS</h1>
                            </div>
                            <div id="pmaas_bookademo_imgtext_wrap">
                                <div id="pmaas_bookademo_img_wrap">
                                    <img id="pmaas_bookademo_img"
                                        loading="lazy" decoding="async"
                                        src={PMaaSMeeting}
                                        alt="Image for PMaas meeting" />
                                </div>
                                <div id="pmaas_bookademo_text_wrap">
                                    <p id="pmaas-first-para">PMaaS provides an on-demand scalable service which is tailored to fit the needs of a client for their project.</p>

                                    <p id="pmaas-second-para">If a project needs to be accelerated or delayed, the team can be scaled up or down to suit the circumstances.</p>

                                    <div id="pmaas_bookademo_link_wrap">
                                        <a class="button_dark" href="/pmaas/">Book a Demo</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Book a demo - END */}

                    {/* Capability and Capacity */}
                    <div id="pmaas_capability_wrap">
                        <div className="pmaas-content-wrap">
                            <div>
                                <p id="pmaas-large-p">PMaaS represents a shift in mindset to a value-based approach, and we place our client’s aims and satisfaction at the centre of our delivery.</p>
                                <div className="pmaas-header-wrap">
                                    <h2>Capability and Capacity</h2>
                                </div>
                                <p>Our PMaaS team consists of subject matter experts who have solid utilities experience. This ensures we deliver tangible, affordable and reliable solutions to our clients. We are specialists in:</p>
                            </div>
                            <div id="pmaas_capability_box_wrap">
                                <div class="pmaas_capability_box">
                                    <h4>Project management</h4>
                                </div>
                                <div class="pmaas_capability_box">
                                    <h4>Utilities fraud</h4>
                                </div>
                                <div class="pmaas_capability_box">
                                    <h4>Software integration and deployment</h4>
                                </div>
                                <div class="pmaas_capability_box">
                                    <h4>Business process re-engineering and automation</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Capability and Capacity - END */}

                    {/* PMaaS Services */}
                    <div id="pmaas_services_wrap">
                        <div className="pmaas-content-wrap">
                            <div className="pmaas-header-wrap">
                                <h2>PMaaS Services</h2>
                            </div>
                            <p>IDenteq offer the following services which can be tailored to a clients need:</p>
                            <div id="pmaas_services_box_wrap">
                                <div class="pmaas_services_box">
                                    <div class="pmaas_services_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img class="pmaas_services_box_image"
                                                src={End2EndIcon}
                                                alt="End-2-end managed project" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="pmaas_services_box_header_wrap">
                                        <h4>End-2-end managed project</h4>
                                    </div>
                                </div>
                                <div class="pmaas_services_box">
                                    <div class="pmaas_services_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img class="pmaas_services_box_image"
                                                src={ExperiencedResourcesIcon}
                                                alt="Experienced resources who can support your existing team" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="pmaas_services_box_header_wrap">
                                        <h4>Experienced resources who can support your existing team</h4>
                                    </div>
                                </div>
                                <div class="pmaas_services_box">
                                    <div class="pmaas_services_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img class="pmaas_services_box_image"
                                                src={ProjectReportingIcon}
                                                alt="Project Reporting" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="pmaas_services_box_header_wrap">
                                        <h4>Project reporting</h4>
                                    </div>
                                </div>
                                <div class="pmaas_services_box">
                                    <div class="pmaas_services_box_image_wrap">
                                        <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                                            <img class="pmaas_services_box_image"
                                                src={ProjectManagementOfficeIcon}
                                                alt="Project management office (PMO)" />
                                        </AnimationOnScroll>
                                    </div>

                                    <div class="pmaas_services_box_header_wrap">
                                        <h4>Project management office (PMO)</h4>
                                    </div>
                                </div>
                            </div>

                            <p id="pmaas-our-exp">Our experience of delivering projects in utilities includes the following areas:</p>

                            <div id="pmaas_services_box_dark_wrap">
                                <div className="pmaas-dark-row">
                                    <div class="pmaas_services_box_dark">
                                        <h4>Supporting water companies with ODI targets &amp; initiatives</h4>
                                    </div>
                                    <div class="pmaas_services_box_dark">
                                        <h4>Combatting Change of Tenancy Fraud</h4>
                                    </div>
                                    <div class="pmaas_services_box_dark">
                                        <h4>Process automation using data and technology</h4>
                                    </div>
                                    <div class="pmaas_services_box_dark">
                                        <h4>Software trials and integrations</h4>
                                    </div>
                                </div>
                                <div className="pmaas-dark-row pmaas-dark-middle-row">
                                    <div class="pmaas_services_box_dark">
                                        <h4>Energy theft avoidance</h4>
                                    </div>
                                    <div class="pmaas_services_box_dark">
                                        <h4>Void/Occupier property management</h4>
                                    </div>
                                    <div class="pmaas_services_box_dark">
                                        <h4>Gap site identification</h4>
                                    </div>
                                    <div class="pmaas_services_box_dark">
                                        <h4>Financial vulnerability</h4>
                                    </div>
                                </div>
                                <div className="pmaas-dark-row">
                                    <div class="pmaas_services_box_dark">
                                        <h4>Revenue and debt initiatives</h4>
                                    </div>
                                    <div class="pmaas_services_box_dark">
                                        <h4>Settlements imbalance</h4>
                                    </div>
                                    <div class="pmaas_services_box_dark">
                                        <h4>Business water market interactions</h4>
                                    </div>
                                    <div class="pmaas_services_box_dark">
                                        <h4>MI report builds</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* PMaaS Services - END */}

                    {/* Our Delivery Model */}
                    <div id="pmaas_deliverymodel_wrap">
                        <div className="pmaas-content-wrap">
                            <div className="pmaas-header-wrap">
                                <h2>Our Delivery Model</h2>
                            </div>
                            <p>At IDenteq, we are always looking for ways that we can use technology and new data sources to drive improvements in process and delivery. We have invested in identifying solutions and qualified resources so that we can meet our clients needs, and we will continue to invest and innovate in this delivery model for the future.</p>
                        </div>
                    </div>
                    {/* Our Delivery Model - END */}

                    {/* PMaaS content - END */}
                </Container>
                <div className="contact-wrap">
                    <Contact page="pmaas" />
                </div>
                <Footer page="pmaas" />
            </Container>
        );
    }
}
