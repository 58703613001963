import styles from "./BDDataSources.module.css"

// Multiple Data Sources
import RoyalMail from '../../img/datasources/Royal-Mail.jpg';
import OrdnanceSurvey from '../../img/datasources/Ordnance-Survey.png';
import VOA from '../../img/datasources/VOA.png';
import HMLandRegistry from '../../img/datasources/HM-Land-Registry.png';
import Google from '../../img/datasources/Google.png';
import CompaniesHouse from '../../img/datasources/Companies-House.png';
import Yell from '../../img/datasources/Yell.png';
import FSA from '../../img/datasources/FSA.png';
import BT from '../../img/datasources/BT.png';
import TripAdvisor from '../../img/datasources/Trip-Advisor.png';
import DVSA from '../../img/datasources/DVSA.png';
import Experian from '../../img/datasources/Experian.png';


export default function BDDataSources() {
    return (
        <div className={styles.dataSources}>
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h2>Multiple Data Sources</h2>
                </div>
                <div className={styles.textWrap}>
                    <p>All the data required to keep track of your business customers is contained within the BD Portal, making it simple and efficient to use data to prevent revenue loss.</p>
                </div>

                <div className={styles.logosRow} >
                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={RoyalMail}
                            alt="Icon Royal Mail" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={OrdnanceSurvey}
                            alt="Icon Ordinance Survey" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={VOA}
                            alt="Icon Valuation Office Agency" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={HMLandRegistry}
                            alt="Icon HM Land Registry" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={Google}
                            alt="Icon Google" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={CompaniesHouse}
                            alt="Icon Companies House" />
                    </div>

                </div>

                <div className={styles.logosRow} id={styles.lastRow}>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={Yell}
                            alt="Icon Yell" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={FSA}
                            alt="Icon FSA" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={BT}
                            alt="Icon BT" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={TripAdvisor}
                            alt="Icon Trip Advisor" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={DVSA}
                            alt="Icon DVSA" />
                    </div>

                    <div className={styles.logoWrap} >
                        <img loading="lazy" decoding="async" width="360" height="240"
                            src={Experian}
                            alt="Icon Experian" />
                    </div>
                </div>

            </div>
            {/* Multiple Data Sources - END */}
        </div >
    )
}