import styles from "./css/ContactForm.module.css"

import React, { useState, useEffect } from 'react'

import Button from "./Button"

import request from '../../../helpers/request';
import endpoints from '../../../helpers/endpoints';

import { logEvent } from "../../../analytics"

export default function ContactForm(props) {
    const [form, setForm] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(null)

    let formEndpoint = ""

    switch(props.page) {
        case "bd-portal":
            formEndpoint = endpoints.BD_PORTAL_LANDING_PAGE_FORM
            break
        case "address-portal":
            formEndpoint = endpoints.ADDRESS_PORTAL_LANDING_PAGE_FORM
            break
        case "prospect-portal":
            formEndpoint = endpoints.PROSPECT_PORTAL_LANDING_PAGE_FORM
            break
        default:
            formEndpoint = endpoints.CONTACT_US
    }

    useEffect(() => {
        if (submitted === true) {
            setForm({})
        }
    }, [submitted])



    const updateContactUsFormValue = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value ? e.target.value : undefined
        })
    }

    const contactUsFormSubmit = (e) => {
        e.preventDefault();

        request().post(formEndpoint, form).then(e => {
            setSubmitted(true)

            logEvent({
                category: "Contact",
                action: "Submit",
                label: "Contact form submitted"
            })
        }).catch(e => {
            setError(e.response.data)
        })
    }
    
    return (
        <div {...props}>
            <form method="post" action="/" onSubmit={contactUsFormSubmit}>
            {error ? (
                <div className="errorBox">
                    <p>There was a problem with your submission. Please review the fields below.</p>
                    <ul>
                        {error.errors?.full_name ? (
                            <li><strong>Name:</strong> {error.errors?.name}</li>
                        ) : <></>}
                        {error.errors?.email ? (
                            <li><strong>Email:</strong> {error.errors?.email}</li>
                        ) : <></>}
                        {error.errors?.phone_number ? (
                            <li><strong>Phone Number:</strong> {error.errors?.phone_number}</li>
                        ) : <></>}
                        {error.errors?.company ? (
                            <li><strong>Company:</strong> {error.errors?.company}</li>
                        ) : <></>}
                        {error.errors?.message ? (
                            <li><strong>Message:</strong> {error.errors?.message}</li>
                        ) : <></>}
                    </ul>
                </div>
            ) : <></>}
                <div className={styles.formRow}>
                    <input type="text" id="FullName" placeholder="Full Name" name="full_name" value={form?.full_name ?? ''} onChange={updateContactUsFormValue} size="1" required />
                    <input type="text" id="Company" placeholder="Company" name="company" value={form?.company ?? ''} onChange={updateContactUsFormValue} size="1" required />
                </div>
                <div className={styles.formRow}>
                    <input type="text" id="PhoneNumber" placeholder="Phone Number" name="phone_number" value={form?.phone_number ?? ''} onChange={updateContactUsFormValue} size="1" required />
                    <input type="text" id="Email" placeholder="Email" name="email" value={form?.email ?? ''} onChange={updateContactUsFormValue} size="1" required />
                </div>
                <div className={styles.formRow}>
                    <select name="interested_in" id="interestedIn" value={form?.interested_in ?? ''} onChange={updateContactUsFormValue} required size="1">
                        <option value="" disabled selected>Interested In</option>
                        <option value="Domestic Solutions">Domestic Solutions</option>
                        <option value="Business Solutions">Business Solutions</option>
                        <option value="Both">Both</option>
                        <option value="I prefer an informal chat">I prefer an informal chat</option>
                    </select>
                </div>
                <div className={styles.formRow}>
                    <select id="Time" name='time' value={form?.time ?? ''} onChange={updateContactUsFormValue} size="1" required>
                        <option value="" disabled selected>Time</option>
                        <option value="Morning">Morning</option>
                        <option value="Afternoon">Afternoon</option>
                    </select>
                </div>
                <div className={styles.formRow}>
                    <textarea id="message" placeholder="Message" name='message' value={form?.message ?? ''} onChange={updateContactUsFormValue} rows="8" size="1" required></textarea>
                </div>
                <Button href="#" onClick={contactUsFormSubmit} text="Submit" color={props.buttonColor || "#33d1ad"} />
            </form>
        </div>
    )
}