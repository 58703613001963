import { useRef, useEffect } from "react"

import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Container from "../components/container/container"
import Contact from "../components/contact/contact"
import ProvidersHero from "../components/ProvidersHero/ProvidersHero"
import CotModules from "../components/CotModules/CotModules"
import CotDataSources from "../components/CotDataSources/CotDataSources"
import CotFeatures from "../components/CotFeatures/CotFeatures"
import ArrangeDemo from "../components/ArrangeDemo/ArrangeDemo"
import ProvidersBDHero from "../components/ProvidersBDHero/ProvidersBDHero"
import BDModules from "../components/BDModules/BDModules"
import BDDataSources from "../components/BDDataSources/BDDataSources"
import BDFeatures from "../components/BDFeatures/BDFeatures"

import styles from "./css/Energy.module.css"

export default function Energy() {
    const demo = useRef(null)
    const domestic = useRef(null)
    const business = useRef(null)

    useEffect(() => {
        document.title = 'Energy | IDenteq'
    }, [])

    return (
        <Container>
            <Header page="energy" />

            <ProvidersHero title="" subtitle="Energise your revenue assurance with advanced data solutions" cotRef={domestic} bdRef={business}>
                <p className={styles.smallText}><strong>It’s time energy providers had access to smarter, more efficient data solutions.</strong> Whether you supply energy to residential consumers, commercial enterprises, or both – IDenteq offers an innovative SaaS solution to fortify your company’s revenue security.</p>
            </ProvidersHero>

            <div className={styles.domesticSolutions} ref={domestic}>
                <div className={styles.contentWrap}>
                    <div className={styles.headerWrap}>
                        <h1>Domestic Solutions</h1>
                    </div>
                    <div className={styles.textWrap}>
                        <div className={styles.h2Wrap}>
                            <h2>Change of Tenancy (COT) Portal</h2>
                        </div>
                        <p>IDenteq’s <strong>Change of Tenancy (COT) Portal</strong> has been specifically designed to empower domestic energy providers to safeguard their revenue streams like never before. Through seamless data aggregation and proactive measures, our COT Portal stands as your automated ally in revenue protection, risk mitigation, debt management, and fraud prevention.</p>
                    </div>
                </div>
            </div>

            <div className={styles.modules}>
                <div className={styles.contentWrap}>
                    <div className={styles.hasYouCovered}>
                        <h2>COT has you covered</h2>
                        <p>The COT Portal contains 8 independent modules to protect revenue and combat common frauds. These modules vary in function, but all share a common thread: advanced data aggregation. This sophisticated data processing forms the foundation of superior revenue protection for domestic energy providers.</p>
                    </div>
                    <CotModules />
                </div>
            </div>

            <CotDataSources>
                <p>All the data required to keep track of your domestic energy customers is contained within the COT Portal, ensuring nothing slips through the net.</p>
            </CotDataSources>

            <CotFeatures />
            <ArrangeDemo page="energy" demoRef={demo} />

            <ProvidersBDHero buttonRef={business} demoRef={demo}>
                <p>IDenteq’s Business Data (BD) portal is purpose-built for <strong>commercial energy suppliers.</strong> In short, it streamlines your revenue assurance by aggregating quality data in a targeted and accessible way. Whether it’s verifying information, confirming changes in tenancy, or spotting potential Phoenix companies, BD Portal is here to save you a lot of time and money.</p>
            </ProvidersBDHero>

            <BDModules page="energy" />
            <BDDataSources />
            <BDFeatures />
            <ArrangeDemo page="energy" demoRef={demo} />

            <div className={styles.contactWrap} ref={demo} >
                <Contact page="energy" />
            </div>
            <Footer page="energy" />
        </Container> 
    )
}
