import styles from "./css/DataSources.module.css"

import Image from "./Image"

export default function DataSources() {
    return (
        <div className={styles.dataSources}>
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/Google_2015.svg" alt="Google" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/Facebook_Logo_2023.svg" alt="Facebook" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/TripAdvisor_Logo.svg" alt="Trip Advisor" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/Yell_Logo.svg" alt="Yell" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/BT_Logo_2019.svg" alt="BT" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/Food_Standards_Agency.svg" alt="Food Standards Agency" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/HM_Land_Registry.svg" alt="HM Land Registry" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/Royal_Mail_Logo.svg" alt="Royal Mail" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/Just_Eat_Logo.svg" alt="Just Eat" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/Care_Quality_Commission_Logo.svg" alt="Care Quality Commission" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/Ordnance_Survey.svg" alt="Ordnance Survey" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/192com.svg" alt="192.com" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/CompaniesHouse.svg" alt="Companies House" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/Driver_and_Vehicle_Standards_Agency_Logo.svg" alt="DVSA" />
                <Image className={styles.img} src="/images/bd-portal-landing-page/img/data-sources/ValuationOffice.svg" alt="Valuation Office Agency" />
        </div>
    )
}