import axios from 'axios';
// import session from '../stores/session';
import eventEmitter from './events';

export default (authed = false) => {
    let obj = {
        // baseURL: sessionStorage.getItem('api-path')
        baseURL: process.env.REACT_APP_BASE_URL
    };

    // console.log(process.env.REACT_APP_BASE_URL);

    if (authed) {
        obj.headers = {
            Authorization: 'Bearer ' + sessionStorage.getItem('user-token')
        }
    }

    let ax = axios.create(obj);

    ax.interceptors.response.use(
        res => res,
        err => {
            // console.log(err.response);

            if (err.message === 'Network Error') {
                return Promise.reject({ status: 500, data: 'Network Error' });
            }

            if ((err.response.status === 403 || err.response.status === 401)) {
                // console.log('Hit a 401 or 403, resetting session. bad 🗞')
                // session.reset();
                eventEmitter.emit('sessionReset')
                // window.alert(401)
            }

            throw err;
        }
    )

    return ax;
}
