'use client'

import styles from "./css/Contact.module.css"

import Section from "./Section"
import ContactForm from "./ContactForm";

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailIcon from '@mui/icons-material/Email';

export default function Contact({ page }) {
    return (
        <Section dark>
            <div className={styles.thumbContainer}>
                <div className={styles.thumbprintBackground} />
            </div>
            <div className={styles.flexbox}>
                <div className={styles.text}>
                    <h2>Prefer to chat with our team?</h2>
                    <div className={styles.getInTouch}>
                        <h4 style={{color: "var(--highlight-color)"}}>Get in touch today.</h4>
                        <div>
                            <PhoneEnabledIcon className={styles.icon} htmlColor={"var(--highlight-color)"} style={{borderColor: "var(--highlight-color)"}} />
                            <p>0333 577 4224</p>
                        </div>
                        <div>
                            <EmailIcon className={styles.icon} htmlColor={"var(--highlight-color)"} style={{borderColor: "var(--highlight-color)"}} />
                            <p>enquiries@identeq.co.uk</p>
                        </div>
                    </div>
                    <p className={styles.largeBlurb}><small>
                        You consent to IDenteq storing and using your name, 
                        email address, telephone number for the purpose of 
                        responding to your enquiry.
                        <br />
                        For more information see our <a href="/privacy-policy" style={{color: "var(--highlight-color)"}}>Privacy Policy</a>
                        </small></p>
                </div>
                <ContactForm className={styles.form} buttonColor={"var(--highlight-color)"} page={page} />
            </div>
            <p className={styles.smallBlurb}><small>
                You consent to IDenteq storing and using your name,
                email address, telephone number for the purpose of
                responding to your enquiry.
                <br />
                For more information see our <a href="/privacy-policy" style={{ color: "var(--highlight-color)"  }}>Privacy Policy</a>
            </small></p>
        </Section>
    )
}