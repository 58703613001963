import styles from "./ProvidersBDHero.module.css"

export default function ProvidersBDHero({ buttonRef, demoRef, children }) {
    return (
        <div className={styles.heroSection} ref={buttonRef}>
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h1>Business Solutions</h1>
                </div> 
                <div className={styles.imgTextWrap}>
                    <div className={styles.imgWrap}>
                        <img src="/images/BD-Portal-Logo-Rectangle.png" alt="COT Portal" />
                    </div>
                    <div className={styles.textWrap}>
                        {children}
                        <div className={styles.buttonWrap}>
                            <a href="#demo" onClick={() => demoRef.current.scrollIntoView({behavior: "smooth"})}>Book a Demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
