import React, { Component } from 'react'

import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'

import './css/privacy-policy.css'

export default class PrivacyPolicy extends Component {

    componentDidMount() {
        document.title = 'Privacy Policy | IDenteq'
    }

    render() {
        return (
            <Container>
                <Header />
                <Container>
                    {/* Privacy Policy content */}
                    <div className="privacy">
                        <div className="headingWrap">
                            <h1>Privacy Policy</h1>
                        </div>
                        <div className="textWrap">
                            <p>IDenteq is committed to protecting your privacy and ensuring the security of any personal information you provide to us. We adhere to the requirements of all applicable data protection laws in line with our Information Security Policy, including the UK Data Protection Act 2018.</p>
                            <p>The purpose of this policy is to explain what personal information IDenteq collect, for what purpose, and how we will ensure it is protected.</p>
                            <h2>The data we collect</h2>
                            <p>Personally identifiable information (PII) is any data that could potentially identify a specific individual. We may collect, process, store and transfer the following types of personal data:</p>
                            <ul>
                                <li>Identity data; relating to individuals including full name, title, date of birth and gender.</li>
                                <li>Contact data; relating to individual’s contact details, including address, email address and home/mobile telephone number;</li>
                                <li>Financial data; relating to credit accounts held in your name</li>
                                <li>Digital identity data; relating to computer or mobile devices including the IP address used to connect to the internet, browser details and operating system on the devices you use to access this website</li>
                            </ul>
                            <p>Sensitive data refers to the various categories of personal data identified by data privacy laws as requiring special treatment. Examples include ethnic origin, political persuasion, criminal records, religious beliefs and physical/mental health data. IDenteq does not process sensitive data.</p>
                            <h2>How we may process the data</h2>
                            <p>We only use your personal data where permitted under the UK Data Protection Act 2018. When we process your personal data, we do so on the basis that our clients have a legitimate interest in verifying identity, authenticating details you provide and preventing fraud, in order to comply with the regulations that apply to them. Processing of the data may also be a contractual requirement of the services you are obtaining from them.</p>
                            <p>We may process your personal data under contractual obligation on behalf of our clients in the following circumstances:</p>
                            <ul>
                                <li>To confirm your identity and authenticate the information you provide</li>
                                <li>To prevent and detect crime such as fraud</li>
                                <li>To provide alerts where information you have provided has changed</li>
                                <li>To comply with the law or regulations</li>
                                <li>To improve data accuracy and completeness</li>
                            </ul>
                            <p>We may also use personal information where we have a legitimate interest to do so. We rely on legitimate interest as our basis for processing in the following circumstances:</p>
                            <p>To maintain our records and other administrative purposes (for example, to manage our employee records)</p>
                            <p>To contact you with business related information that we consider may be of interest to you</p>
                            <p>We may also process your personal data with your consent when we collect your information from you through cookies via our website. This data is used for the following purposes:</p>
                            <ul>
                                <li>Remembering preferences, so you do not have to keep re-entering your choices when you visit again</li>
                                <li>Measuring how you use our site, so that changes can be made to ensure it meets your needs</li>
                            </ul>
                            <p>You will be asked to consent to this before using our website, and if you choose not to give your consent, or you later remove it, we will not process your personal information in this way.</p>
                            <h2>How we collect your data</h2>
                            <p>IDenteq uses the following methods to collect personal data:</p>
                            <ul>
                                <li>Directly
                                    <ul>
                                        <li>when we contact you with business related information that we consider may be of interest to you</li>
                                        <li>when we liaise with you for business purposes</li>
                                        <li>when you submit a job application to us</li>
                                        <li>when you submit an enquiry to us by phone or email</li>
                                    </ul>
                                </li>
                                <li>Through our website
                                    <ul>
                                        <li>when you visit our website, we may automatically collect information about the computer or mobile devices used. This information is collected through cookies. You can set your browser to prevent this happening</li>
                                        <li>when you submit an enquiry to us via a form on our website</li>
                                    </ul>
                                </li>
                                <li>From third parties
                                    <ul>
                                        <li>who provide services to you, to us or to our clients, such as credit reference, fraud prevention or government agencies and other reference data providers</li>
                                        <li>who provide publicly available data, such as the press, the electoral register, company registers and online search engines</li>
                                    </ul>
                                </li>
                            </ul>
                            <h2>How we keep your personal information secure</h2>
                            <p>We take data security extremely seriously, and use a variety of the latest technologies and procedures to protect your personal information from unauthorised access, destruction, use or disclosure. We limit access to your personal data to those employees and third parties who have a business need to know. They will only process your personal data based on our instructions and they are subject to a contractual duty of confidentiality.</p>
                            <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
                            <p>IDenteq holds ISO27001, Cyber Essentials Plus and IASME Governance Standard certifications, and we perform risk assessments against our internal and external facing applications annually (as a minimum).</p>
                            <h2>How long do we keep your data for</h2>
                            <p>We will only retain your personal data for as long as necessary to fulfil the purpose we collected it for, including for the purposes of satisfying any legal, contractual, or reporting requirements.</p>
                            <p>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those through other means, and the applicable legal requirements.</p>
                            <h2>Where we will transfer your data</h2>
                            <p>We do not transfer your personal data outside of the European Economic Area. From the 1st January 2021, all data stored by IDenteq will be held inside the United Kingdom.</p>
                            <h2>Your rights</h2>
                            <p>You have the following rights in relation to the processing of your personal information:</p>
                            <ul>
                                <li>Request access to your personal data (commonly known as “data subject access request”)</li>
                                <li>Request a rectification to your personal data</li>
                                <li>Request that your personal data is erased • Object to our processing of your personal data</li>
                                <li>Request transfer of your personal data (for example to another party)</li>
                                <li>Withdraw your consent (where we are relying on consent to process your personal data)</li>
                                <li>Lodge complaints with the regulator</li>
                            </ul>
                            <p>Your rights may vary depending on the circumstances and the basis of processing.</p>
                            <h2>Voluntary Modern Slavery Statement</h2>
                            <p>This statement sets out IDenteq’s position and action to understand the potential modern slavery risks related to its activities.</p>
                            <p>‘Modern slavery’ is defined by the Modern Slavery Act 2015 (“the Act”) as a term encapsulating the offences of slavery, servitude and forced or compulsory labour, and human trafficking. It is used with this definition throughout the statement, which supports compliance with section 54 of the Act.</p>
                            <p>We oppose slavery and human trafficking in all its forms and have put in place steps that are aimed at ensuring that there is no slavery or human trafficking in our own business – and that in so far as possible, our suppliers and clients hold the same ethos.</p>
                            <p>As an organisation, we are committed to valuing human rights, championing equality and challenging discrimination. We take our role seriously in taking a robust approach to the global problem of slavery and human trafficking.</p>
                            <p>We are absolutely committed to preventing slavery and human trafficking in our business activities, and to ensure that suppliers and clients we engage with are free from slavery and human trafficking. If an existing or potential supplier or client were to be suspected of having links to involvement in modern slavery, our arrangements with them will immediately cease.</p>
                            <p>To help identify and monitor the risk of slavery and human trafficking, we request that our suppliers and clients agree to the principles of this statement by completing modern slavery and human trafficking due diligence questions upon business engagement.</p>
                            <p>IDenteq has policies and procedures in place to ensure compliance with UK employment law.  IDenteq policies with direct relevance to modern slavery include safeguarding, whistleblowing, grievance, equality diversity and inclusion and dignity at work.  Our staff, suppliers or clients are not in any category which is generally seen to be vulnerable to modern slavery in the UK.</p>
                            <p>To ensure a high level of understanding of the risks of modern slavery and human trafficking, IDenteq is fully committed to providing the necessary training to our staff and partners in order to raise awareness, so they can:</p>
                            <ul>
                                <li>Recognise the risks of modern slavery and human trafficking in the supply or client chain.</li>
                                <li>Identify and report potential violations of The Modern Slavery Act 2015.</li>
                            </ul>
                            <p>This statement is made voluntarily, in support of the principles of the Modern Slavery Act 2015.</p>
                            <h2>Contact us</h2>
                            <p>If you have any questions about this privacy policy, wish to exercise your rights in relation to your personal data, or wish to make a complaint about our use of your data, please contact us on the following details:</p>
                            <p>Postal address: Suite G14 Innovation Way, Stoke-On-Trent, England, ST6 4BF</p>
                            <p>Company no: 12195983</p>
                            <p>Vat no: 337859846</p>
                            <p>ICO Number: ZA563244</p>
                            <p>Telephone number: 0333 577 4224</p>
                            <p>If you have any concerns about our processing activities, you can contact the Information Commissioner’s Office (www.ico.org.uk). Before doing so, please allow us the opportunity to deal with your concerns by contacting us on the details provided.</p>
                        </div>
                    </div>
                    {/* Privacy Policy - END */}
                </Container>
                <Footer />
            </Container>
        );
    }
}