import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Container from '../../components/container/container';

import PostHero from './PostHero'
import PostInfo from './PostInfo'
import PostContent from './PostContent'
import PostSuggestions from './PostSuggestions'

export default function PublicationPost() {
    const params = useParams()
    const [post, setPost] = useState({})

    useEffect(() => {
        document.title = 'IDenteq'
        request(true).get(endpoints.PUBLICATIONS + '/' + params.post_slug)
        .then(json => {
            setPost(json.data)
            document.title = json.data.title + ' | IDenteq'
        })
        .catch(err => window.location.href = "/")
    }, [params.post_slug])

    return (
        <Container>
            <Header />
            <div>
                <PostHero post={post} />
                <PostInfo post={post} />
                <PostContent post={post} />
                <PostSuggestions post={post} />
            </div>
            <Footer />
        </Container>
    );
}