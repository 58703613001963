import removeMarkdown from "markdown-to-text";

import styles from "./css/Snippet.module.css"

export default function Snippet({ post }) {

    const postDate = new Date(post.published_at)
    const month = postDate.toLocaleString('default', { month: 'long' })
    const day = postDate.toLocaleString('default', { day: 'numeric' })
    const year = postDate.toLocaleString('default', { year: 'numeric' })
    const formattedDate = `${month} ${day}, ${year}`

    const postText = removeMarkdown(post.content)
    const blurb = truncateOnWord(postText, 100) + "..."

    return (
        <div className={styles.container}>
            <a href={"/publications/" + post.slug}><img src={post.image_path} alt={post.title} /></a>
            <div className={styles.info}>
                <a href={"/publications/" + post.slug}><h4>{post.title}</h4></a>
                <p className={styles.date}>{formattedDate}</p>
                <p className={styles.blurb}>{blurb}</p>
            </div>
        </div>
    )
}

function truncateOnWord(str, limit) {
    var trimmable = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
    var reg = new RegExp('(?=[' + trimmable + '])');
    var words = str.split(reg);
    var count = 0;
    return words.filter(function (word) {
        count += word.length;
        return count <= limit;
    }).join('');
}