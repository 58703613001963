import styles from "./css/PostHero.module.css"

export default function PostHero({ post }) {
    return (
        <div className={styles.blogHero}>
            <div className={styles.heroText}>
                <h1>{post.title}</h1>
                {post.summary ? <p>{post.summary}</p> : <></>}
            </div>
            <div className={styles.backgroundDivider} />
        </div>
    )
}