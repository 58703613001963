import styles from "./BDModules.module.css"

import "animate.css"
import { AnimationOnScroll } from "react-animation-on-scroll"

import AddressAlertsIcon from '../../img/icons/Address-Alerts-Icon.png';
import CompaniesHouseAlertsIcon from '../../img/icons/Companies-House-Alerts-Icon.png';
import ContactValidationIcon from '../../img/icons/Contact-Validation-Icon.png';
import OccupierValidationIcon from '../../img/icons/Occupier-Validation-Icon.png';
import VacantCOTAlertsIcon from '../../img/icons/Vacant-COT-Alerts-Icon.png';
import PhoenixAlertsIcon from '../../img/icons/Phoenix-Alerts-Icon.png';
import PropertySearchIcon from '../../img/icons/Property-Search-Icon.png';

export default function BDModules({ page }) {
    
    let blurb = ""

    switch (page) {
        case "water":
            blurb = "Business Data Portal has been built to meet the challenges facing commercial water providers today, and the solution can be deployed in full or on a modular basis."
            break;
        case "energy":
            blurb = "Business Data Portal has been built to meet the challenges facing commercial energy providers today, and the solution can be deployed in full or on a modular basis."
            break;
        default:
            blurb = ""
    }

    return (
        <div className={styles.background}>
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h2>Protect revenue on all fronts</h2>
                </div>

                <div className={styles.textWrap}>
                    <p>{blurb}</p>
                </div>

                <div className={styles.boxesWrap}>

                    {/* Address Alerts */}
                    <div className={styles.moduleBox}>
                        <a href="https://www.identeq.co.uk/bd-address-alerts/">
                            <div className={styles.boxImgWrap} >
                                <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                    <img loading="lazy" decoding="async" width="90" height="90"
                                        src={AddressAlertsIcon}
                                        alt="Icon Image for COT Validation" />
                                </AnimationOnScroll>
                            </div>

                            <div className={styles.boxTextWrap} >
                                <h4>Address Alerts</h4>
                                <p>Notification of property changes including change of use</p>
                            </div>
                        </a>
                    </div>
                    {/* Address Alerts - END */}

                    {/* Companies House Alerts */}
                    <div className={styles.moduleBox}>
                        <a href="https://www.identeq.co.uk/companies-house-alerts/">
                            <div className={styles.boxImgWrap} >
                                <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                    <img loading="lazy" decoding="async" width="90" height="90"
                                        src={CompaniesHouseAlertsIcon}
                                        alt="Icon Image for Tariff Validation" />
                                </AnimationOnScroll>
                            </div>

                            <div className={styles.boxTextWrap} >
                                <h4>Companies House Alerts</h4>
                                <p>Notification of company updates from Companies House</p>
                            </div>
                        </a>
                    </div>
                    {/* Companies House Alerts - END */}

                    {/* Contact Validation */}
                    <div className={styles.moduleBox}>
                        <a href="ihttps://www.identeq.co.uk/bd-contact-validation/">
                            <div className={styles.boxImgWrap} >
                                <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                    <img loading="lazy" decoding="async" width="90" height="90"
                                        src={ContactValidationIcon}
                                        alt="Icon Image for COT Fraud Alerts" />
                                </AnimationOnScroll>
                            </div>

                            <div className={styles.boxTextWrap} >
                                <h4>Contact Validation</h4>
                                <p>Validate &amp; append<br/>telephone numbers<br/>from multiple data sources</p>
                            </div>
                        </a>
                    </div>
                    {/* Contact Validation - END */}

                    {/* Occupier Validation */}
                    <div className={styles.moduleBox}>
                        <a href="https://www.identeq.co.uk/occupier-validation/">
                            <div className={styles.boxImgWrap} >
                                <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                    <img loading="lazy" decoding="async" width="90" height="90"
                                        src={OccupierValidationIcon}
                                        alt="Icon Image for Address Alerts" />
                                </AnimationOnScroll>
                            </div>

                            <div className={styles.boxTextWrap} >
                                <h4>Occupier Validation</h4>
                                <p>Validation that a company is trading from the supply premises</p>
                            </div>
                        </a>
                    </div>
                    {/* Occupier Validation - END */}
                </div>
                <div className={styles.boxesWrap} id={styles.lastRow} >
                    {/* Vacant COT Alerts */}
                    <div className={styles.moduleBox}>
                        <a href="https://www.identeq.co.uk/bd-vacant-cot-alerts/">
                            <div className={styles.boxImgWrap} >
                                <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                    <img loading="lazy" decoding="async" width="90" height="90"
                                        src={VacantCOTAlertsIcon}
                                        alt="Icon Image for Contact Validation" />
                                </AnimationOnScroll>
                            </div>

                            <div className={styles.boxTextWrap} >
                                <h4>Vacant COT Alerts</h4>
                                <p>Notification of new occupiers for vacant properties</p>
                            </div>
                        </a>
                    </div>
                    {/* Vacant COT Alerts - END */}

                    {/* Phoenix Alerts */}
                    <div className={styles.moduleBox}>
                        <a href="https://www.identeq.co.uk/phoenix-alerts">
                            <div className={styles.boxImgWrap} >
                                <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                    <img loading="lazy" decoding="async" width="90" height="90"
                                        src={PhoenixAlertsIcon}
                                        alt="Icon Image for Owner Validation" />
                                </AnimationOnScroll>
                            </div>

                            <div className={styles.boxTextWrap} >
                                <h4>Phoenix Alerts</h4>
                                <p>Notification of potential phoenix companies &amp; directors</p>
                            </div>
                        </a>
                    </div>
                    {/* Phoenix Alerts - END */}

                    {/* Real Time Validation */}
                    <div className={styles.moduleBox}>
                        <a href="https://www.identeq.co.uk/realtime-validation/">
                            <div className={styles.boxImgWrap} >
                                <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                                    <img loading="lazy" decoding="async" width="90" height="90"
                                        src={PropertySearchIcon}
                                        alt="Icon Image for Gone Away Alerts" />
                                </AnimationOnScroll>
                            </div>

                            <div className={styles.boxTextWrap} >
                                <h4>Real Time Validation</h4>
                                <p>Real-time property searches to identify occupiers</p>
                            </div>
                        </a>
                    </div>
                    {/* Real Time Validation - END */}

                </div>
            </div>
        </div>
    )
}