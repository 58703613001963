import styles from "./css/PostInfo.module.css"

import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';

export default function PostInfo({ post }) {
    
    const postDate = new Date(post.published_at)
    const month = postDate.toLocaleString('default', { month: 'long' })
    const day = postDate.toLocaleString('default', { day: 'numeric' })
    const year = postDate.toLocaleString('default', { year: 'numeric' })
    const formattedDate = `${month} ${day}, ${year}`

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d)
    }

    return (
        <div className={styles.blogInfo}>
            <div className={styles.imgContainer}>
                <img src={post.image_path} alt={post.title} styles={styles.image} />
            </div>
            <div className={styles.postMetadata}>
                <table>
                    <tr>
                        {/* <td className={styles.iconPadding}><img src="/icons/186402_grid_icon.svg" alt={post.post_type} className={styles.icon} /></td> */}
                        <td className={styles.iconPadding}><GridViewRoundedIcon className={styles.icon} /></td>
                        <td className={styles.info}>{post?.post_type}</td>
                    </tr>
                    <tr>
                        {/* <td className={styles.iconPadding}><img src="/icons/calendar.svg" alt="Published date" className={styles.icon} /></td> */}
                        <td className={styles.iconPadding}><CalendarTodayRoundedIcon className={styles.icon} /></td>
                        <td className={styles.info}>{isValidDate(postDate) ? formattedDate : ""}</td>
                    </tr>
                    {post?.time_to_read ? <tr>
                        {/* <td className={styles.iconPadding}><img src="/icons/clock.svg" alt="Time to read" className={styles.icon} /></td> */}
                        <td className={styles.iconPadding}><QueryBuilderRoundedIcon className={styles.icon} /></td>
                        <td className={styles.info}>{post?.time_to_read}</td>
                    </tr> : <></>}
                </table>
                <div className={styles.authors}>
                    {post.authors?.length > 1 ? <h4>Authors</h4> : <h4>Author</h4>}
                    <ul className={styles.authorsList}>
                        {post.authors?.map(author => (
                            <li key={author}>{author}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}