import React, { Component } from 'react'

import Header from './/../components/header/header'
import Footer from './/../components/footer/footer'
import Container from './/../components/container/container'
import Contact from './/../components/contact/contact'

import './css/publications.css';

import NapsDataQuality from '../img/publications/naps-data-quality.png';
import Phoenix from '../img/publications/phoenix.png';
import ChangeOfTenancy from '../img/publications/change-of-tenancy.png';
import AdvancedTechnology from '../img/publications/advanced-technology.png';
import UnderstandingUtilityFraud from '../img/publications/understanding-utility-fraud.png';
import OutsourcingDataAndrew from '../img/publications/outsourcing-data-andrew.png';
import DataManagement from '../img/publications/data-management.png';
import DataSecurity from '../img/publications/data-security.png';
import WaterIndustry from '../img/publications/water-industry.png';
import AdvancedDataAnalytics from '../img/publications/advanced-data-analytics.png';
import OutsourcingDataBen from '../img/publications/outsourcing-data-ben.png';
import UtilityFraudCrisis from '../img/publications/utility-fraud-crisis.png';

export default class Publications extends Component {

    componentDidMount() {
        document.title = 'Publications | IDenteq'
    }

    render() {
        return (
            <Container>
                <Header page="publications" />
                <Container>
                    {/* Publications content */}

                    <div id="publications-background-wrap">
                        <div className="publications-content-wrap">
                            <div id="publications_header_wrap">
                                <h1>Publications</h1>
                            </div>

                            {/* Snippets */}
                            <div id="publications_box_wrap">
                                {/* NAPS Data Quality */}
                                <div class="publications_box">

                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/naps-data-quality/"><img loading="lazy" decoding="async"
                                            src={NapsDataQuality}
                                            alt="NAPS Data Quality" /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/naps-data-quality/">NAPS Data Quality</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Andrew McKinney
                                            <span> and </span>
                                            Bill Yip
                                        </p>
                                        <p>IDenteq was commissioned by MOSL to carry out a data quality project looking at the Business Water Supply Connections which are entering the market through the New Connections process. The project was funded by the Market Improvement Fund, which is overseen by the Strategic Panel and administered by MOSL.</p>
                                    </div>

                                </div>
                                {/* NAPS Data Quality - END */}

                                {/* Phoenix */}
                                <div class="publications_box">

                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/phoenix-from-the-flames-business-fraud-and-how-to-spot-it-early/"><img loading="lazy" decoding="async"
                                            src={Phoenix}
                                            alt="Phoenix from the flames – business fraud and how to spot it early." /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/phoenix-from-the-flames-business-fraud-and-how-to-spot-it-early/">Phoenix from the flames – business fraud and how to spot it early.</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Ian Parry
                                        </p>
                                        <p>Here’s 10 important questions you should ask to help you better understand how prepared your business is for phoenix business fraud in the utilities sector.</p>
                                    </div>
                                </div>
                                {/* Phoenix  - END */}

                                {/* Change of Tenancy */}
                                <div class="publications_box">

                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/managing-change-of-tenancy-cot-challenges-for-utilities-providers/"><img loading="lazy" decoding="async"
                                            src={ChangeOfTenancy}
                                            alt="Managing Change of Tenancy (CoT) Challenges for Utilities Providers" /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/managing-change-of-tenancy-cot-challenges-for-utilities-providers/">Managing Change of Tenancy (CoT) Challenges for Utilities Providers</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Andrew McKinney
                                        </p>
                                        <p> The Change of Tenancy (CoT) process can be a challenge for utilities providers, especially when it comes to managing customer accounts and ensuring accurate billing. Here are some of the CoT challenges that utilities providers...</p>
                                    </div>
                                </div>
                                {/* Change of Tenancy - END */}

                                {/* Advanced Technology */}
                                <div class="publications_box">

                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/how-advanced-technology-is-helping-utilities-tackle-fraud/"><img loading="lazy" decoding="async"
                                            src={AdvancedTechnology}
                                            alt="How advanced technology is helping utilities tackle fraud" /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/how-advanced-technology-is-helping-utilities-tackle-fraud/">How advanced technology is helping utilities tackle fraud</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Ben Reed
                                        </p>
                                        <p> Utilities companies face many challenges, but perhaps one of the most pressing is the issue of fraud. The cost of fraud to utilities is substantial and can impact not just the companies themselves but also their customers...</p>
                                    </div>
                                </div>
                                {/* Advanced Technology - END */}

                                {/* Understanding Utility Fraud */}
                                <div class="publications_box">

                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/understanding-the-types-and-causes-of-utility-fraud/"><img loading="lazy" decoding="async"
                                            src={UnderstandingUtilityFraud}
                                            alt="Understanding the types and causes of utility fraud" /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/understanding-the-types-and-causes-of-utility-fraud/">Understanding the types and causes of utility fraud</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Andrew McKinney
                                        </p>
                                        <p>Utility fraud is a serious business - and a big one. Every year, it costs utility companies billions in lost revenue.&nbsp; Manifesting in many different forms, utility fraud can range from small-scale activities such as tampering with metres, to large-scale...</p>
                                    </div>
                                </div>
                                {/* Understanding Utility Fraud - END */}

                                {/* Outsourcing Data Andrew */}
                                <div class="publications_box">

                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/the-benefits-of-outsourcing-data-services-for-revenue-protection-in-the-utilities-sector-2/"><img loading="lazy" decoding="async"
                                            src={OutsourcingDataAndrew}
                                            alt="The benefits of outsourcing data services for revenue protection in the Utilities sector" /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/the-benefits-of-outsourcing-data-services-for-revenue-protection-in-the-utilities-sector-2/">The benefits of outsourcing data services for revenue protection in the Utilities sector</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Andrew McKinney
                                        </p>
                                        <p>Outsourcing data services for revenue protection in the utilities sector can provide many benefits for utilities companies. By working with a specialised data services company, utilities can gain access to advanced data analytics, data management, and other...</p>
                                    </div>
                                </div>
                                {/* Outsourcing Data Andrew - END */}

                                {/* Data Management */}
                                <div class="publications_box">

                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/the-role-of-data-management-in-ensuring-accurate-billing-and-preventing-revenue-loss-for-utilities/"><img loading="lazy" decoding="async"
                                            src={DataManagement}
                                            alt="The role of data management in ensuring accurate billing and preventing revenue loss for Utilities" /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/the-role-of-data-management-in-ensuring-accurate-billing-and-preventing-revenue-loss-for-utilities/">The role of data management in ensuring accurate billing and preventing revenue loss for Utilities</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Andrew McKinney
                                        </p>
                                        <p>To state the obvious, accurate billing is crucial for Utility companies to protect their revenue and maintain customer satisfaction. However, with the increasing amount of data being generated from a range of sources, managing and utilising this data can be a...</p>
                                    </div>
                                </div>
                                {/* Data Management - END */}

                                {/* Data Security */}
                                <div class="publications_box">
                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/why-utilities-companies-need-to-prioritise-data-security-in-the-digital-age/"><img loading="lazy" decoding="async"
                                            src={DataSecurity}
                                            alt="Why Utilities Companies Need to Prioritise Data Security in the Digital Age" /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/why-utilities-companies-need-to-prioritise-data-security-in-the-digital-age/">Why Utilities Companies Need to Prioritise Data Security in the Digital Age</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Ben Reed
                                        </p>
                                        <p>In today's world, where everything from energy usage to billing information is stored electronically, data security is more important than ever for utilities companies. A breach could not only harm a company's reputation but also put their customers at risk. In this...</p>
                                    </div>
                                </div>
                                {/* Data Security - END */}

                                {/* Water Industry */}
                                <div class="publications_box">

                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/identeq-sponsors-water-industry-awards-to-support-pioneering-innovative-solutions-in-the-water-sector/"><img loading="lazy" decoding="async"
                                            src={WaterIndustry}
                                            alt="IDenteq sponsors Water Industry Awards to support pioneering innovative solutions in the water sector" /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/identeq-sponsors-water-industry-awards-to-support-pioneering-innovative-solutions-in-the-water-sector/">IDenteq sponsors Water Industry Awards to support pioneering innovative solutions in the water sector</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Ben Reed
                                        </p>
                                        <p>The Water Industry Awards 2023 have been launched to acknowledge and celebrate the achievements of water companies and their partners in the past year. Despite the challenges brought on by the cost of living crisis and pollution issues, the hard work and dedication of...</p>
                                    </div>
                                </div>
                                {/* Water Industry - END */}

                                {/* Advanced Data Analytics */}
                                <div class="publications_box">
                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/how-advanced-data-analytics-can-improve-revenue-protection-and-reduce-costs-for-utilities/"><img loading="lazy" decoding="async"
                                            src={AdvancedDataAnalytics}
                                            alt="How advanced data analytics can improve revenue protection and reduce costs for Utilities" /></a>
                                    </div>

                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/how-advanced-data-analytics-can-improve-revenue-protection-and-reduce-costs-for-utilities/">How advanced data analytics can improve revenue protection and reduce costs for Utilities</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Andrew McKinney
                                        </p>
                                        <p>Advanced data analytics can be a powerful tool for utilities companies to improve revenue protection and reduce costs. By analysing large amounts of data from various reference sources, utilities can gain valuable insights into their operations, customers, and...</p>
                                    </div>
                                </div>
                                {/* Advanced Data Analytics - END */}

                                {/* Outsourcing Data Ben */}
                                <div class="publications_box">
                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/the-benefits-of-outsourcing-data-services-for-revenue-protection-in-the-utilities-sector/"><img loading="lazy" decoding="async"
                                            src={OutsourcingDataBen}
                                            alt="The benefits of outsourcing data services for revenue protection in the Utilities sector" /></a>
                                    </div>
                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/the-benefits-of-outsourcing-data-services-for-revenue-protection-in-the-utilities-sector/">The benefits of outsourcing data services for revenue protection in the Utilities sector</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Ben Reed
                                        </p>
                                        <p>Outsourcing data services for revenue protection in the utilities sector can provide many benefits for utilities companies. By working with a specialised data services company, utilities can gain access to advanced data analytics, data management, and other...</p>
                                    </div>
                                </div>
                                {/* Outsourcing Data Ben - END */}

                                {/* Utility Fraud Crisis */}
                                <div class="publications_box">
                                    <div class="publications_box_img_wrap">
                                        <a href="/publications/utilities-fraud-and-the-cost-of-living-crisis-a-perfect-storm/"><img loading="lazy" decoding="async"
                                            src={UtilityFraudCrisis}
                                            alt="Utilities Fraud and the cost-of-living crisis …a perfect storm!" /></a>
                                    </div>
                                    <div class="publications_box_text_wrap">
                                        <h2>
                                            <a href="/publications/utilities-fraud-and-the-cost-of-living-crisis-a-perfect-storm/">Utilities Fraud and the cost-of-living crisis …a perfect storm!</a>
                                        </h2>
                                        <p className="post-meta">
                                            <span>by </span>
                                            Ian Parry
                                        </p>
                                        <p>A combination of no agreed definition, low levels of investigations and a cost of living crisis will lead to more and more people turning to Fraud to avoid paying their Water and Energy bills.</p>
                                    </div>
                                </div>
                                {/* Utility Fraud Crisis - END */}
                            </div>
                            {/* Snippets - END */}
                        </div>
                    </div>

                    {/* Take Control */}
                    <div id="publications_takecontrol_background_wrap">
                        <div className="publications-content-wrap">
                            <div className="takecontrol-header-wrap">
                                <h2>Take control of your customer information today</h2>
                            </div>
                            <p>By using Contact Validation, Energy and Water companies can improve their debt collection process, reduce the risk of errors, and improve customer satisfaction by ensuring that customer information is accurate and up-to-date.</p>
                            <p>Don’t let compliance issues and lost revenue hold you back any longer. Book a free demo today, and we’ll show you the difference it can make for your company!</p>
                            <div id="publications_takecontrol_btn_wrap">
                                <a class="button_dark" href="/contact/">Show me how</a>
                            </div>
                        </div>
                    </div>
                    {/* Take Control - END */}

                    {/* Publications content - END */}
                </Container>
                <div className="contact-wrap">
                    <Contact page="publications" />
                </div>
                <Footer />
            </Container>
        );
    }
}
