import React from 'react';

import './container.scss';

export default function Container({ children }) {
    return <div className="container">
        <div>
            {children}
        </div>
    </div>;
}