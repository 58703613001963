'use client'

import { useState, useEffect } from "react"

import styles from "./css/DownloadModal.module.css"

import { Modal } from "@mui/material"

import endpoints from "../../../helpers/endpoints"
import request from "../../../helpers/request"

import { logEvent } from "../../../analytics"

import BigButton from "./BigButton"
import Button from "./Button"

export default function DownloadModal({ url, buttonText, text, page, hoverColor }) {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)

    const [form, setForm] = useState({page: page})
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(null)


    useEffect(() => {
        if (submitted) {
            setForm({page: page})
        }
    }, [submitted])

    const handleOpen = () => {
        logEvent({
            category: 'Download Modal',
            action: 'Open',
            label: 'Download modal opened'
        })

        setOpen(true)
    }

    const updateContactUsFormValue = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value || undefined
        })
    }

    const contactUsFormSubmit = (e) => {
        e.preventDefault()

        request().post(endpoints.DOWNLOAD_FORM, form).then(e => {
            setSubmitted(true)
            handleDownload()
            handleClose()
        }).catch(e => {
            setError(e.response.data)
        })
    }

    const handleDownload = () => {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', '')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        logEvent({
            category: 'Download Modal',
            action: 'Download',
            label: 'Form submitted and file downloaded',
        })
    }

    return (
        <div>
            <BigButton onClick={handleOpen} text={buttonText} hoverColor={hoverColor}/>
            <Modal open={open} onClose={handleClose}>
                <div className={styles.modal}>
                    <h4>{text || "To access this file, please enter your name and email address below"}</h4>
                    <form action="/">
                        {error ? (
                            <div className={styles.errorBox}>
                                <p>There was a problem with your submission. Please review the fields below.</p>
                                <ul>
                                    {error.errors?.name ? (
                                        <li><strong>Name:</strong> {error.errors?.name}</li>
                                    ) : <></>}
                                    {error.errors?.email ? (
                                        <li><strong>Email:</strong> {error.errors?.email}</li>
                                    ) : <></>}
                                </ul>
                            </div>
                        ) : <></>}
                        <div className={styles.formRow}>
                            <input type="text" name="name" id="name" placeholder="Full Name" value={form?.name ?? ''} onChange={updateContactUsFormValue} size="1" />
                            <input type="text" name="email" id="email" placeholder="Email" value={form?.email ?? ''} onChange={updateContactUsFormValue} size="1" />
                        </div>
                        <p className={styles.smallBlurb}><small>
                            You consent to IDenteq storing and using your name,
                            email address, telephone number for the purpose of
                            responding to your enquiry.
                            <br />
                            For more information see our <a href="#" style={{ color: "#0d73b2" }}>Privacy Policy</a>
                        </small></p>
                        <Button onClick={contactUsFormSubmit} text="Submit" color={"#0d73b2"} download />
                    </form>
                </div>
            </Modal>
        </div>
    )
}