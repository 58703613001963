import styles from "./css/Section.module.css"

export default function Section({ children, className, dark, style }) {
    return (
        <div className={`${dark ? styles.dark :  ""} ${styles.section} ${className}`} style={style}>
            <div className={styles.contentWrap}>
                {children}
            </div>
        </div>
    )
}