import App from './App.js';
import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import endpoints from './helpers/endpoints.js';
import session from './stores/session.js';
import sessionStore from './stores/session.js';
import axios from 'axios';
import { initGA, logPageView } from './analytics.js';

import BDPortal from './pages/bdportal.jsx';
import Careers from './pages/careers.jsx';
import ChangeOfUse from './pages/changeofuse.jsx';
import ActivatePage from './pages/activate.jsx';
import Reset from './pages/reset.jsx';
import DoReset from './pages/doReset.jsx';
import ContactUs from './pages/contactus.jsx';
import CotPortal from './pages/cotportal.jsx';
import PrivacyPolicy from './pages/privacy-policy.jsx';
import PMaaS from './pages/pmaas.jsx';
import Publications from './pages/publications.jsx';
import Technology from './pages/technology.jsx';
import Testimonials from './pages/testimonials.jsx';
import PublicationPost from './pages/publicationPost/index.jsx'
import MoslNapsReport from './pages/mosl-naps-report.jsx';
import Water from './pages/water.jsx';
import Energy from './pages/energy.jsx';
import PortalModule from './pages/portalModule/index.jsx';
import BDPortalLandingPage from './pages/redesign/BDPortalLandingPage/index.jsx';
import AddressPortalLandingPage from './pages/redesign/AddressPortalLandingPage/index.jsx';
import ProspectPortalLandingPage from './pages/redesign/ProspectPortalLandingPage/index.jsx';

export default class Router extends Component {

    state = {
        loaded: false,
        stage: 'Detecting Environment'
    }

    componentDidMount() {
        initGA()
        logPageView()

        let a = axios.create({
            baseURL: endpoints.BASE
        });
 
        a.get(endpoints.DOMAIN, {
            timeout: 7000
        }).then(e => {

            this.setState({ stage: 'Configuring App' })

            if (e.data.api_url) {
                sessionStorage.setItem('api-path', e.data.api_url);
            }
 
            // console.log('Retrieved API Path: ' + sessionStorage.getItem('api-path'));
 
            setTimeout(() => {
                this.setState({
                    loaded: true
                })
            }, 1000);

        }).catch(() => {
            this.setState({
                stage: 'Failed to get environment, contact support'
            })
        })
    }
    
    render() {
        return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/bd-portal" element={<BDPortal />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/change-of-use" element={<ChangeOfUse />} />
                <Route path="/activate" element={<ActivatePage />} />
                <Route path="/password-reset" element={<Reset />} />
                <Route path="/auth/reset" element={<DoReset />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/cot-portal" element={<CotPortal />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/pmaas" element={<PMaaS />} />
                <Route path="/publications" element={<Publications />} />
                <Route path="/technology" element={<Technology />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/mosl-naps-report" element={<MoslNapsReport />} />
                <Route path="/water" element={<Water />} />
                <Route path="/energy" element={<Energy />} />
                <Route path="/publications/:post_slug" element={<PublicationPost />} />
                <Route path="/modules/:module_slug" element={<PortalModule />} />
                <Route path="/bd-portal-landing-page" element={<BDPortalLandingPage />} />
                <Route path="/address-portal-landing-page" element={<AddressPortalLandingPage />} />
                <Route path="/prospect-portal-landing-page" element={<ProspectPortalLandingPage />} />

                {/** Redirects for URLs from old Wordpress site */}
                <Route path="/naps-data-quality" element={<Navigate to="/publications/naps-data-quality" />} />
                <Route path="/phoenix-from-the-flames-business-fraud-and-how-to-spot-it-early" element={<Navigate to="/publications/phoenix-from-the-flames-business-fraud-and-how-to-spot-it-early" />} />
                <Route path="/managing-change-of-tenancy-cot-challenges-for-utilities-providers" element={<Navigate to="/publications/managing-change-of-tenancy-cot-challenges-for-utilities-providers" />} />
                <Route path="/how-advanced-technology-is-helping-utilities-tackle-fraud" element={<Navigate to="/publications/how-advanced-technology-is-helping-utilities-tackle-fraud" />} />
                <Route path="/understanding-the-types-and-causes-of-utility-fraud" element={<Navigate to="/publications/understanding-the-types-and-causes-of-utility-fraud" />} />
                <Route path="/the-benefits-of-outsourcing-data-services-for-revenue-protection-in-the-utilities-sector-2" element={<Navigate to="/publications/the-benefits-of-outsourcing-data-services-for-revenue-protection-in-the-utilities-sector-2" />} />
                <Route path="/the-role-of-data-management-in-ensuring-accurate-billing-and-preventing-revenue-loss-for-utilities" element={<Navigate to="/publications/the-role-of-data-management-in-ensuring-accurate-billing-and-preventing-revenue-loss-for-utilities" />} />
                <Route path="/why-utilities-companies-need-to-prioritise-data-security-in-the-digital-age" element={<Navigate to="/publications/why-utilities-companies-need-to-prioritise-data-security-in-the-digital-age" />} />
                <Route path="/identeq-sponsors-water-industry-awards-to-support-pioneering-innovative-solutions-in-the-water-sector" element={<Navigate to="/publications/identeq-sponsors-water-industry-awards-to-support-pioneering-innovative-solutions-in-the-water-sector" />} />
                <Route path="/how-advanced-data-analytics-can-improve-revenue-protection-and-reduce-costs-for-utilities" element={<Navigate to="/publications/how-advanced-data-analytics-can-improve-revenue-protection-and-reduce-costs-for-utilities" />} />
                <Route path="/the-benefits-of-outsourcing-data-services-for-revenue-protection-in-the-utilities-sector" element={<Navigate to="/publications/the-benefits-of-outsourcing-data-services-for-revenue-protection-in-the-utilities-sector" />} />
                <Route path="/utilities-fraud-and-the-cost-of-living-crisis-a-perfect-storm" element={<Navigate to="/publications/utilities-fraud-and-the-cost-of-living-crisis-a-perfect-storm" />} />
                <Route path="/business-data-portal" element={<Navigate to="/bd-portal" />} />
                <Route path="/data-cleanse-and-validation" element={<Navigate to="/modules/cp-data-cleanse-and-validation" />} />
                <Route path="/tariff-validation" element={<Navigate to="/modules/cp-tariff-validation" />} />
                <Route path="/cot-fraud-alerts" element={<Navigate to="/modules/cp-cot-fraud-alerts" />} />
                <Route path="/cot-address-alerts" element={<Navigate to="/modules/cp-cot-address-alerts" />} />
                <Route path="/contact-validation" element={<Navigate to="/modules/cp-contact-validation" />} />
                <Route path="/owner-validation" element={<Navigate to="/modules/cp-owner-validation" />} />
                <Route path="/gone-away-alerts" element={<Navigate to="/modules/cp-gone-away-alerts" />} />
                <Route path="/void-cot-alerts" element={<Navigate to="/modules/cp-void-cot-alerts" />} />
                <Route path="/bd-address-alerts" element={<Navigate to="/modules/bd-address-alerts" />} />
                <Route path="/companies-house-alerts" element={<Navigate to="/modules/bd-companies-house-alerts" />} />
                <Route path="/bd-contact-validation" element={<Navigate to="/modules/bd-contact-validation" />} />
                <Route path="/occupier-validation" element={<Navigate to="/modules/bd-occupier-validation" />} />
                <Route path="/bd-vacant-cot-alerts" element={<Navigate to="/modules/bd-vacant-cot-alerts" />} />
                <Route path="/phoenix-alerts" element={<Navigate to="/modules/bd-phoenix-alerts" />} />
                <Route path="/realtime-validation" element={<Navigate to="/modules/bd-realtime-validation" />} />

                <Route path='*' element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
        )
    }
}
