import React, { useState, useEffect } from 'react'

import styles from "./CareersContactForm.module.css"

import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';


export default function CareersContactForm() {
    const [form, setForm] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (submitted === true) {
            setForm({})
        }
    }, [submitted])



    const updateContactUsFormValue = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value ? e.target.value : undefined
        })
    }

    const contactUsFormSubmit = (e) => {
        e.preventDefault();

        request().post(endpoints.CAREERS_FORM, form).then(e => {
            setSubmitted(true)
        }).catch(e => {
            setError(e.response.data)
        })
    }
    
    return (
        <form className={styles.contactForm} method="post" action="/" onSubmit={contactUsFormSubmit}>
            {error ? (
                <div className={styles.errorBox}>
                    <p>There was a problem with your submission. Please review the fields below.</p>
                    <ul>
                        {error.errors?.first_name ? (
                            <li><strong>Name:</strong> {error.errors?.name}</li>
                        ) : <></>}
                        {error.errors?.last_name ? (
                            <li><strong>Company:</strong> {error.errors?.company}</li>
                        ) : <></>}
                        {error.errors?.email ? (
                            <li><strong>Email:</strong> {error.errors?.email}</li>
                        ) : <></>}
                        {error.errors?.message ? (
                            <li><strong>Message:</strong> {error.errors?.message}</li>
                        ) : <></>}
                    </ul>
                </div>
            ) : <></>}
            <div id={styles.firstNameWrap} className={styles.inputWrap}>
                <input type="text" id="FirstName" placeholder="First Name" name="first_name" value={form?.first_name ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div id={styles.lastNameWrap} className={styles.inputWrap}>
                <input type="text" id="LastName" placeholder="Last Name" name="last_name" value={form?.last_name ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div id={styles.emailWrap} className={styles.inputWrap}>
                <input type="text" id="Email" placeholder="Email" name="email" value={form?.email ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div className={styles.inputWrap}>
                <select id="vacancy" name="vacancy" value={form?.vacancy ?? ''} onChange={updateContactUsFormValue} required>
                    <option value="" disabled selected>Vacancy</option>
                    <option value="First Choice">First Choice</option>
                    <option value="Second Choice">Second Choice</option>
                    <option value="Third Choice">Third Choice</option>
                </select>
            </div>

            <div className={styles.inputWrap}>
                <textarea id="message" placeholder="Message" name="message" value={form?.message ?? ''} onChange={updateContactUsFormValue} required></textarea>
            </div>

            <div class="submit-container">
                <button
                    id="form_Contact_submit"
                    class="button_light">Submit</button>
                {/* <img
                                        loading="lazy"
                                        decoding="async"
                                        src="https://www.identeq.co.uk/wp-content/plugins/wpforms/assets/images/submit-spin.svg"
                                        id="form_Contact_submit_spinner" style="display: none;" alt="Loading" /> */}
            </div>
        </form>
    )
}