import styles from "./CotFeatures.module.css"

import "animate.css"
import { AnimationOnScroll } from 'react-animation-on-scroll';

// How it Works
import FraudPreventionIcon from '../../img/icons/Fraud-prevention-Icon.svg';
import MultipleDataSourcesIcon from '../../img/icons/Multiple-Data-Sources-Icon.svg';
import ProactiveMonitoringIcon from '../../img/icons/Proactive-Monitoring-Icon.svg';
import AnalysisIcon from '../../img/icons/Analysis-Icon.svg';

export default function CotFeatures() {
    return (
        <div className={styles.background}>
            <div className={styles.boxesWrap}>
                {/* Fraud Prevention */}
                <div className={styles.box} id={styles.cotGreen}>
                    <div className={styles.boxImgWrap}>
                        <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                            <img loading="lazy" decoding="async" width="90" height="90"
                                src={FraudPreventionIcon}
                                alt="Icon Image for fraud prevention" />
                        </AnimationOnScroll>
                    </div>

                    <div className={styles.boxTextWrap}>
                        <p>Stay ahead of the curve with <strong>real-time data insights,</strong> enabling you to proactively protect your revenue.</p>
                    </div>
                </div>
                {/* Fraud Prevention - END */}

                {/* Multiple Data Sources */}
                <div className={styles.box} id={styles.cotGreen}>
                    <div className={styles.boxImgWrap}>
                        <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                            <img loading="lazy" decoding="async" width="90" height="90"
                                src={MultipleDataSourcesIcon}
                                alt="Icon Image for multiple data sources" />
                        </AnimationOnScroll>
                    </div>

                    <div className={styles.boxTextWrap}>
                        <p>Identify and mitigate risks swiftly with our <strong>data-driven tools,</strong> ensuring financial stability and customer satisfaction.</p>
                    </div>
                </div>
                {/* Multiple Data Sources - END */}

                {/* Proactive Monitoring */}
                <div className={styles.box} id={styles.cotGreen}>
                    <div className={styles.boxImgWrap}>
                        <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                            <img loading="lazy" decoding="async" width="90" height="90"
                                src={ProactiveMonitoringIcon}
                                alt="Icon Image for proactive monitoring" />
                        </AnimationOnScroll>
                    </div>

                    <div className={styles.boxTextWrap}>
                        <p>Streamline debt management processes by leveraging the power of comprehensive data, enabling <strong>targeted and effective debt recovery.</strong></p>
                    </div>
                </div>
                {/* Proactive Monitoring - END */}

                {/* Analysis */}
                <div className={styles.box} id={styles.cotGreen}>
                    <div className={styles.boxImgWrap}>
                        <AnimationOnScroll animateIn="animate__fadeTop" animatePreScroll={false} animateOnce={true}>
                            <img loading="lazy" decoding="async" width="90" height="90"
                                src={AnalysisIcon}
                                alt="Icon Image for analysis" />
                        </AnimationOnScroll>
                    </div>

                    <div className={styles.boxTextWrap}>
                        <p>Our <strong>COT Portal is your vigilant guardian</strong> against common fraud schemes, ensuring that your revenue remains untainted.</p>
                    </div>
                </div>
                {/* Analysis - END */}
            </div>
        </div>
    )
}