import { useEffect } from "react"

import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Container from "../components/container/container"
import Contact from "../components/contact/contact"

import styles from "./css/MoslNapsReport.module.css"

export default function MoslNapsReport() {

    useEffect(() => {
        document.title = 'MOSL NAPS Report | IDenteq'
    }, [])

    return (
        <Container>
            <Header />

            <div className={styles.headerSection}>
                <div className={styles.contentWrap}>
                    <div className={styles.headerWrap}>
                        <h1>NAPS Data Quality Project Report</h1>
                    </div>
                    <div className={styles.textWrap}>
                        <p>What were the main successes or conclusions drawn from this project – did it achieve what it was set out to do?</p>
                        <p>Were there any findings that were particularly unexpected?</p>
                        <p>What's next for this project? How do you hope that the findings of this report are used?</p>
                    </div>
                    <a className={styles.button_dark} href="/reports/MOSL-NAPS-Report.pdf">Download: NAPS Data Quality Project Report</a>
                </div>
            </div>

            <div className={styles.contactWrap}>
                <Contact page="mosl-naps-report" />
            </div>
            <Footer />
        </Container>
    )    
}