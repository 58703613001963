import React, { Component } from 'react'

import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import Contact from '../components/contact/contact'

import './css/careers.css';

import BenReed from '../img/staff/Ben-Reed.jpg';
import AndrewMcKinney from '../img/staff/Andrew-McKinney.png';

export default class Careers extends Component {

    componentDidMount() {
        document.title = 'Careers | IDenteq'
    }

    render() {
        return (
            <Container>
                <Header page="careers" />
                {/* Careers content */}
                <Container>

                    {/* Join Our Team */}
                    <div id="careers_joinOurTeam" class="careers_row">

                        <div class="careers_header_wrap">
                            <h1>Careers at IDenteq</h1>
                        </div>
                        <div class="careers_text_wrap">
                            <p id="would-you-like">Would you like to be part of a successful growing team that prides itself on delivering innovation for clients… Are you keen to use the latest technology to change the way processes work in utilities?</p>
                            <p id="if-you-possess">If you possess a can-do attitude and a core skill set within project management, data analysis, software development or platform engineering contact us today.</p>
                            <a class="button_dark" href="/contact/">Join our team</a>
                        </div>
                    </div>
                    {/* Join Our Team - END */}

                    {/* Directors */}
                    <div id="careers_directors_wrap" class="careers_row">
                        <div className="content-wrap">
                            <div class="careers_header_wrap">
                                <h2>Directors</h2>
                            </div>
                            <div className="directors">
                                <div id="ben_wrap" class="careers_director_wrap">
                                    <div class="careers_director_img_wrap">
                                        <img loading="lazy" decoding="async" width="1000" height="695"
                                            src={BenReed}
                                            alt="Profile Image for Ben Reed" />
                                    </div>
                                    <div class="director-info">
                                        <h4>Ben Reed</h4>
                                        <p>Director</p>
                                    </div>
                                </div>
                                <div id="andrew_wrap" class="careers_director_wrap">
                                    <div class="careers_director_img_wrap">
                                        <img loading="lazy" decoding="async" width="1000" height="695"
                                            src={AndrewMcKinney}
                                            alt="Profile Image for Andrew McKinney" />
                                    </div>
                                    <div class="director-info">
                                        <h4>Andrew McKinney</h4>
                                        <p>Director</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Directors - END */}

                    {/* Working at IDenteq */}
                    <div id="careers_workingAtIDenteq" class="careers_row">
                        <div class="careers_header_wrap">
                            <h2>Working at IDenteq</h2>
                        </div>

                        <div class="careers_workingAtIDenteq_row">
                            <div class="careers_workingAtIDenteq_box">
                                <h4>Customer Success</h4>
                                <p>We listen to our customers and build effective solutions that meet their needs. Our client focus ensures that our full team is always pulling in the same direction – with the goal of creating better results for our users.</p>
                            </div>
                            <div class="careers_workingAtIDenteq_box">
                                <h4>Innovation</h4>
                                <p>We are happy to try new things and to learn as we go, and pushing the boundaries makes us tick. This applies to our problem solving approach and our use of technology, creating growth opportunities for our team.</p>
                            </div>
                        </div>

                        <div class="careers_workingAtIDenteq_row" id="row-2">
                            <div class="careers_workingAtIDenteq_box">
                                <h4>Balance</h4>
                                <p>We have developed a culture where there is enough capability and consideration for colleagues to ensure a healthy work life balance. We believe in flexible working hours to suit the individuals that make up IDenteq.</p>
                            </div>
                            <div class="careers_workingAtIDenteq_box">
                                <h4>Technology</h4>
                                <p>We are a tech company using the latest cloud and serverless technology, perfect for progressive thinkers who want to apply and develop new skills.</p>
                            </div>
                        </div>
                    </div>
                    {/* Working at IDenteq - END */}

                </Container>
                {/* Careers content - END */}
                <div className="contact-wrap">
                    <Contact page="careers" />
                </div>
                <Footer page="careers" />
            </Container>
        );
    }
}