import styles from "./CotDataSources.module.css"

// Multiple Data Sources
import Experian from '../../img/datasources/Experian.png';
import HMLandRegistry from '../../img/datasources/HM-Land-Registry.png';
import RoyalMail from '../../img/datasources/Royal-Mail.jpg';
import OrdnanceSurvey from '../../img/datasources/Ordnance-Survey.png';
import DataOnDemand from '../../img/datasources/Data-on-Demand.png';
import BT from '../../img/datasources/BT.png';
import Propalt from '../../img/datasources/Propalt.png';
import Re_mover from '../../img/datasources/Re-mover.png'
import VRS from '../../img/datasources/VRS.png';
import Mortascreen from '../../img/datasources/Mortascreen.png';
import NFI from '../../img/datasources/NFI.png';
import GoogleMaps from '../../img/datasources/Google-Maps.png';
import GovUK from '../../img/datasources/Gov-UK.png';
import Zoopla from '../../img/datasources/Zoopla.png';

export default function CotDataSources({ children }) {
    return (
        <div className={styles.dataSources} >
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h2>Multiple Data Sources</h2>
                </div>
                <div className={styles.textWrap}>
                    {children}
                </div>

                <div className={styles.logosWrap} >
                    <div className={styles.logosRow} >
                        <img loading="lazy" decoding="async"
                            src={Experian}
                            alt="Experian" />
                        <img loading="lazy" decoding="async"
                            src={HMLandRegistry}
                            alt="HM Land Registry" />
                        <img loading="lazy" decoding="async"
                            src={RoyalMail}
                            alt="Royal Mail" />
                        <img loading="lazy" decoding="async"
                            src={OrdnanceSurvey}
                            alt="Ordnance Survey" />
                        <img loading="lazy" decoding="async"
                            src={DataOnDemand}
                            alt="Data on Demand" />
                        <img loading="lazy" decoding="async"
                            src={BT}
                            alt="BT" />
                    </div>

                    <div className={styles.logosRow} >
                        <img loading="lazy" decoding="async"
                            src={Propalt}
                            alt="Propalt" />
                        <img loading="lazy" decoding="async"
                            src={Re_mover}
                            alt="Re-mover" />
                        <img loading="lazy" decoding="async"
                            src={VRS}
                            alt="VRS" />
                        <img loading="lazy" decoding="async"
                            src={Mortascreen}
                            alt="Mortascreen" />
                        <img loading="lazy" decoding="async"
                            src={NFI}
                            alt="NFI" />
                        <img loading="lazy" decoding="async"
                            src={GoogleMaps}
                            alt="Google Maps" />
                    </div>
                    <div className={styles.logosRow} id={styles.lastRow}>
                        <img loading="lazy" decoding="async"
                            src={GovUK}
                            alt="Gov UK" />
                        <img loading="lazy" decoding="async"
                            src={Zoopla}
                            alt="Zoopla" />
                    </div>
                </div>
            </div>
        </div>
    )
}