import { store } from '@risingstack/react-easy-state';
import request from '../helpers/request';
import eventEmitter from '../helpers/events';

if (!sessionStorage.length) {
    sessionStorage.setItem('getSessionStorage', Date.now());
    sessionStorage.removeItem('getSessionStorage');
}


window.addEventListener('storage', (e) => {
    if (e.key === 'getSessionStorage') {
        sessionStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
        sessionStorage.removeItem('sessionStorage');
    } else if (e.key === 'sessionStorage' && !sessionStorage.length) {
        var data = JSON.parse(e.newValue);

        for (let key in data) {
            // console.log(key, data[key]);
            sessionStorage.setItem(key, data[key]);
        }

        session.fetch();
        window.location.href = window.location.search.split('?')[1];
    }
});

const session = store({
    token: sessionStorage.getItem('user-token'),
    user: {
        id: null,
        name: null,
        permissions: [],
        bookmarks: []
    },
    fetch: () => {
        return request(true).get('/self').then(e => {
            // console.log('fetched');
            session.user.id = e.data.id;
            session.user.name = e.data.name;
            return;
        }).catch(e => {
            session.reset();
        });
    },
    reset: () => {
        if (session.user.id) {
            request(true).get('/logout')//.then(() => console.log('SESSION: LOGGED OUT')).catch(() => console.log('SESSION: FAILED TO LOGOUT (Already logged out?)'));
        }
        session.token = null;
        sessionStorage.removeItem('user-token');
        session.user = {
            id: null,
            name: null,
            permissions: [],
            bookmarks: [],
        };
        session.company = null;
    },
    isAlive: () => {
        if (session.token) {
            return true
        }

        return false;
    }
});

if (session.user.id === null && session.token !== null) {
    session.fetch();
}

eventEmitter.on('sessionReset', session.reset)

export default session;
