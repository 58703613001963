import styles from "./css/Navbar.module.css"

export default function Navbar() {
    return (
        <div className={styles.navbar}>
            <div className={`${styles.navContent} ${styles.contentWrap}`}>
                <div className={styles.logoWrap}>
                    <img src="/images/bd-portal-landing-page/img/Identeq-logo-half-colour.svg" alt="IDenteq" />
                </div>
                <div className={styles.navWrap}>
                    <ul>
                        <li>About</li>
                        <li>Industries</li>
                        <li>Solutions</li>
                        <li>Get in touch</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}