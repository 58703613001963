import React, { Component } from 'react'

import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import Contact from '../components/contact/contact'

import styles from './css/ContactUs.module.css'

export default class ContactUs extends Component {

    componentDidMount() {
        document.title = 'Contact Us | IDenteq'
    }
    
    render() {
        return (
            <Container>        
                <Header page="contact" />
                <div className={styles.contactWrap}>
                    <div className={styles.headerWrap}>
                        <h1>Contact</h1>
                    </div>
                    <Contact page="contactus" />
                </div>
                <Footer page="contactus" />
            </Container>
        );
    }
}