import React, { useState, useEffect } from 'react'
import './contact.scss';
import Container from '../container/container'
import CareersContactForm from '../CareersContactForm/CareersContactForm';
import DemoContactForm from '../DemoContactForm/DemoContactForm';
import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';

import { useMediaQuery } from 'react-responsive';

export default function Contact({page}) {
    const [form, setForm] = useState({})
    const [error, setError] = useState(null)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)' })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 981px)' })

    const updateContactUsFormValue = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value ? e.target.value : undefined
        })
    }

    const contactUsFormSubmit = (e) => {
        e.preventDefault();

        request().post(endpoints.CONTACT_US, form).then(e => {
            setError(null)
            setForm({})
        }).catch(e => {
            setError(e.response.data)
        })
    }
    
    let defaultForm = (
        <form id="form_Contact" method="post" action="/" onSubmit={contactUsFormSubmit}>
            {error ? (
                <div className="errorBox">
                    <p>There was a problem with your submission. Please review the fields below.</p>
                    <ul>
                        {error.errors?.full_name ? (
                            <li><strong>Name:</strong> {error.errors?.name}</li>
                        ) : <></>}
                        {error.errors?.email ? (
                            <li><strong>Email:</strong> {error.errors?.email}</li>
                        ) : <></>}
                        {error.errors?.phone_number ? (
                            <li><strong>Phone Number:</strong> {error.errors?.phone_number}</li>
                        ) : <></>}
                        {error.errors?.company ? (
                            <li><strong>Company:</strong> {error.errors?.company}</li>
                        ) : <></>}
                        {error.errors?.message ? (
                            <li><strong>Message:</strong> {error.errors?.message}</li>
                        ) : <></>}
                    </ul>
                </div>
            ) : <></>}
            <div id="FullName_wrap" class="form_Contact_wrap">
                <input type="text" id="FullName" placeholder="Full Name" name='full_name' value={form?.full_name ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div id="Company_wrap" class="form_Contact_wrap">
                <input type="text" id="Company" placeholder="Company" name='company' value={form?.company ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div id="PhoneNumber_wrap" class="form_Contact_wrap">
                <input type="text" id="PhoneNumber" placeholder="Phone Number" name='phone_number' value={form?.phone_number ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div id="Email_wrap" class="form_Contact_wrap">
                <input type="text" id="Email" placeholder="Email" name='email' value={form?.email ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div class="form_Contact_wrap">
                <select id="InterestedIn" name='interested_in' value={form?.interested_in ?? ''} onChange={updateContactUsFormValue} required>
                    <option value="" disabled selected>Interested In</option>
                    <option value="Domestic Solutions">Domestic Solutions</option>
                    <option value="Business Solutions">Business Solutions</option>
                    <option value="Both">Both</option>
                    <option value="I prefer an informal chat">I prefer an informal chat</option>
                </select>
            </div>
            <div class="form_Contact_wrap">
                <select id="Time" name='time' value={form?.time ?? ''} onChange={updateContactUsFormValue} required>
                    <option value="" disabled selected>Time</option>
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                </select>
            </div>

            <div class="form_Contact_wrap">
                <textarea id="message" placeholder="Message" name='message' value={form?.message ?? ''} onChange={updateContactUsFormValue} required></textarea>
            </div>

            <div class="submit-container">
                <button
                    id="form_Contact_submit"
                    class="button_light">Submit</button>
                {/* <img
                                        loading="lazy"
                                        decoding="async"
                                        src="https://www.identeq.co.uk/wp-content/plugins/wpforms/assets/images/submit-spin.svg"
                                        id="form_Contact_submit_spinner" style="display: none;" alt="Loading" /> */}
            </div>
        </form>
    )

    let title = "Contact Us"
    let Form = defaultForm;
    switch(page) {
        case "careers":
            title = "Join our Team"
            Form = (<CareersContactForm />)
            break;
        case "cotportal":
            title = "Arrange a Demo"
            Form = (<DemoContactForm />)
            break;
        case "bdportal":
            title = "Arrange a Demo"
            Form = (<DemoContactForm />)
            break;
        case "water":
            title = "Arrange a Demo"
            Form = (<DemoContactForm />)
            break;
        case "energy":
            title = "Arrange a Demo"
            Form = (<DemoContactForm />)
            break;
        default:
            title = "Contact Us"
    }

    return (
        <Container>
            {/* Contact */}
            <div id="contact_wrap" class="row">

                <div className="right">

                    <div class="contact_inner">
                        {page != "contactus" ? <h2>{title}</h2> : <></>}
                        <p>Our team are happy to chat to you about your needs and our services.</p>
                        <p id="get_in_touch">Get in touch today.</p>
                    </div>

                    <div class="contact_wrap">
                        <div id="tel_img_wrap" class="contact_inner">
                            <a href="tel:03335774224%20">
                                {isDesktopOrLaptop && <i class="fa-solid fa-phone fa-2xl contactImg"></i>}
                                {isTabletOrMobile && <i class="fa-solid fa-phone fa-xl contactImg"></i>}
                            </a>
                        </div>
                        <div id="tel_num_wrap" class="contact_inner">
                            <a href="tel:03335774224%20">
                                <h4>0333 577 4224</h4>
                            </a>
                        </div>
                    </div>

                    <div class="contact_wrap">
                        <div id="email_img_wrap" class="contact_inner">
                            <a href="mailto:enquiries@identeq.co.uk">
                                {isDesktopOrLaptop && <i class="fa-regular fa-envelope fa-2xl contactImg"></i>}
                                {isTabletOrMobile && <i class="fa-regular fa-envelope fa-xl contactImg"></i>}
                            </a>
                        </div>
                        <div id="email_text_wrap" class="contact_inner">
                            <a href="mailto:enquiries@identeq.co.uk">
                                <h4>enquiries@identeq.co.uk</h4>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="left">
                    <div class="et_pb_code_inner">
                        {Form}
                        <div id="contact_form_privacy" class="contact_inner">
                            <p>
                                You consent to IDenteq storing and using your name, email, address, telephone number for the purpose of responding to your enquiry.
                                <br />
                                For more information see our <a href="/privacy-policy/">Privacy Policy</a>.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            {/* Contact - END */}
        </Container>
    );
}