import Card from "./Card"

export default function DarkCard({ children }) {
    const darkCardStyles = {
        backgroundColor: "#1e2140",
        color: "white",
    }

    return (
        <Card style={darkCardStyles}>
            {children}
        </Card>
    )
}