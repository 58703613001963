import styles from "./css/ModuleHero.module.css"

import Markdown from "markdown-to-jsx"

import ResponsivePlayer from "../../components/ResponsivePlayer/ResponsivePlayer"

export default function ModuleHero({ module }) {
    return (
        <div className={styles.container}>
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h1>{module.title}</h1>
                </div>
                <div className={styles.info}>
                    <div className={styles.left}>
                        <h3>{module.subtitle}</h3>
                        <div className={styles.imgContainer}>
                            {/* <img src={module.video_path} alt={module.title} /> */}
                            <ResponsivePlayer url={module.video_path} thumbnail={module.thumbnail_path} />
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div><Markdown className={styles.largeText}>{module.large_text}</Markdown></div>
                        <div><Markdown className={styles.smallText}>{module.small_text}</Markdown></div>
                        <div className={styles.buttonWrap}>
                            <a href="/contact">Book a free demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}