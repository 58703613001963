import React, { useState, useEffect } from 'react'

import styles from "./DemoContactForm.module.css"

import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';

export default function DemoContactForm() {
    const [form, setForm] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (submitted === true) {
            setForm({})
        }
    }, [submitted])



    const updateContactUsFormValue = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value ? e.target.value : undefined
        })
    }

    const contactUsFormSubmit = (e) => {
        e.preventDefault();

        request().post(endpoints.ARRANGE_DEMO, form).then(e => {
            setSubmitted(true)
        }).catch(e => {
            setError(e.response.data)
        })
    }
    
    return (
        <form className={styles.contactForm} method="post" action="/" onSubmit={contactUsFormSubmit}>
            {error ? (
                <div className={styles.errorBox}>
                    <p>There was a problem with your submission. Please review the fields below.</p>
                    <ul>
                        {error.errors?.full_name ? (
                            <li><strong>Name:</strong> {error.errors?.name}</li>
                        ) : <></>}
                        {error.errors?.email ? (
                            <li><strong>Email:</strong> {error.errors?.email}</li>
                        ) : <></>}
                        {error.errors?.phone_number ? (
                            <li><strong>Phone Number:</strong> {error.errors?.phone_number}</li>
                        ) : <></>}
                        {error.errors?.company ? (
                            <li><strong>Company:</strong> {error.errors?.company}</li>
                        ) : <></>}
                        {error.errors?.message ? (
                            <li><strong>Message:</strong> {error.errors?.message}</li>
                        ) : <></>}
                    </ul>
                </div>
            ) : <></>}
            <div id="FullName_wrap" class="form_Contact_wrap">
                <input type="text" id="FullName" placeholder="Full Name" name="full_name" defaultValue={form?.full_name ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div id="Company_wrap" class="form_Contact_wrap">
                <input type="text" id="Company" placeholder="Company" name="company" defaultValue={form?.company ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div id="PhoneNumber_wrap" class="form_Contact_wrap">
                <input type="text" id="PhoneNumber" placeholder="Phone Number" name="phone_number" defaultValue={form?.phone_number ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div id="Email_wrap" class="form_Contact_wrap">
                <input type="text" id="Email" placeholder="Email" name="email" defaultValue={form?.email ?? ''} onChange={updateContactUsFormValue} required />
            </div>
            <div className={styles.inputWrap}>
                <select id="arrage-a-demo" name="product" defaultValue={form?.product ?? ''} onChange={updateContactUsFormValue} required>
                    <option value="" disabled selected>Arrange a Demo</option>
                    <option value="COT Portal">COT Portal</option>
                    <option value="Business Data Portal">Business Data Portal</option>
                    <option value="PMaaS">PMaaS</option>
                    <option value="Other">Other</option>
                </select>
            </div>

            <div className={styles.inputWrap}>
                <textarea id="message" placeholder="Message" name="message" defaultValue={form?.message ?? ''} onChange={updateContactUsFormValue} required></textarea>
            </div>

            <div class="submit-container">
                <button
                    id="form_Contact_submit"
                    class="button_light">Submit</button>
                {/* <img
                                        loading="lazy"
                                        decoding="async"
                                        src="https://www.identeq.co.uk/wp-content/plugins/wpforms/assets/images/submit-spin.svg"
                                        id="form_Contact_submit_spinner" style="display: none;" alt="Loading" /> */}
            </div>
        </form>
    )
}