import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import request from '../helpers/request'
import endpoints from '../helpers/endpoints'

import styles from './css/ActivatePage.module.css'

export default function ActivatePage(props) {
    const [form, setForm] = useState({ action: "", signature: ""})
    const [errors, setErrors] = useState({})
    const [passwordStrong, setPasswordStrong] = useState(false)
    const [passwordMatch, setPasswordMatch] = useState(false)

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const navigate = useNavigate()

    const updateFormValue = (e) => {
        if (e.target.name === 'password') {
            if (e.target.value.match(/(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/)) {
                setPasswordStrong(true)
            } else {
                setPasswordStrong(false)
            }

            setPasswordMatch(e.target.value === form.confirmPassword && passwordStrong);
        }

        if (e.target.name === 'confirmPassword') {
            setPasswordMatch(e.target.value === form.password && passwordStrong);
        }

        setForm({
            ...form,
            [e.target.name]: e.target.value ? e.target.value : undefined
        });
    }

    const formSubmit = (e) => {
        e.preventDefault();
        if (!(form.password === form.confirmPassword)) {
            return setErrors({
                password: ['Passwords do not match.']
            });
        }
        request(false).post(endpoints.ACTIVATE_POST, form).then(r => {
            window.location.href = "/change-of-use"
        }).catch(r => {
            setErrors(r.response.data.errors);
        });
    }

    useEffect(() => {
        if (sessionStorage.getItem('api-path') === null) {
            handleWait();
        } else {
            handleReady();
        }
    }, [])

    const handleWait = () => {
        setTimeout(() => {
            if (sessionStorage.getItem('api-path') === null) {
                handleWait();
            } else {
                handleReady();
            }
        }, 250);
    }


    const handleReady = () => {
        let queryString = window.location.href.substring(window.location.href.indexOf('?') + 1);
        let signature = queryString.substring(queryString.indexOf('signature') + 10);
        //console.log(url);
        request(false).post(endpoints.ACTIVATE, {
            action: params.get('action'),
            signature: signature
        }).then(r => {
            if (r.data.valid) {
                setForm({
                    action: params.get('action'),
                    signature: signature,
                })
            } else {
                window.location.href = "/change-of-use"
            }
        }).catch(r => {
            window.location.href = "/change-of-use"
        })
    }

    return (
        <Container>
            <Header />
            <div className={styles.contentWrap}>
                <h2>Activate</h2>
                <div className={styles.formWrap}>
                    <p className={styles.info}>Please enter a password for your account</p>
                    {/* <p className="error">{errors}</p> */}
                    <form onSubmit={formSubmit} className="grid grid-gap-15">
                        {errors.password ? (
                            <div className={styles.errorBox}>
                                {errors.password.map((value, index) => {
                                    return (
                                        <p key={index}>
                                            {value}
                                        </p>
                                    )
                                })}
                            </div>
                        ) : <></>}
                        <input onChange={updateFormValue} name="password" placeholder="Password" type="password" required />
                        <input onChange={updateFormValue} name="confirmPassword" placeholder="Confirm Password" className={`input ${passwordMatch ? 'color-green' : ''}`} type="password" required />
                        <button className={styles.activateButton}>Activate</button>
                    </form>
                </div>
            </div>
            <Footer />
        </Container>
    )
}