import React, { useState, useEffect } from 'react';
import request from '../helpers/request'
import endpoints from '../helpers/endpoints'

import Container from '../components/container/container';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';

import styles from "./css/Reset.module.css"

export default function Reset(props)
{
    const [message, setMessage] = useState(null)
    const [form, setForm] = useState({email: ''})

    useEffect(() => {
        document.title = 'Password Reset | IDenteq'
    }, [])

    const formSubmit = () => {
        request(false).post(endpoints.RESET_REQUEST, {
            email: form.email
        }).catch(r => {
            // console.error(r);
        });

        setMessage('If a user exists with this email, then a password reset link will be sent. Please check your inbox.');
    }

    const enterKeyDown = (e) => {
        if (e.which === 13) {
            formSubmit();
        }
    }

    return (
        <Container>
            <Header />
            <div className={styles.contentWrap}>
                <h2>Reset Password</h2>

                {message ? 
                    <p className={styles.message}>{message}</p>
                    :
                    <div className={styles.formWrap}>
                        <p>To reset your password, please enter your email address below.</p>
                        <input onKeyDown={enterKeyDown} onChange={e => setForm({ email: e.target.value })} name="email" placeholder="Email" className="input" type="text" autocomplete="off" required />
                        <button className={styles.resetButton} onClick={formSubmit}>Reset Password</button>
                    </div>
                }

            </div>
            <Footer />
        </Container>
    );
}