import React, { useState, useEffect } from 'react'
import request from '../helpers/request'
import endpoints from '../helpers/endpoints'
import session from '../stores/session'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import FileSaver from 'file-saver'

import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'

import styles from "./css/ChangeOfUse.module.css"
import { SubtitlesOutlined } from '@mui/icons-material'

export default function ChangeOfUse() {
    const [loginForm, setLoginForm] = useState({})
    const [createUserForm, setCreateUserForm] = useState({marketing: true})
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [filesLoading, setFilesLoading] = useState(false)
    const [files, setFiles] = useState([])

    useEffect(() => {
        document.title = 'Change of Use Registration | IDenteq'
        if (session.isAlive()) {
           getFiles(); 
        }
    }, [session])

    useEffect(() => {
        setCreateUserForm(prevForm => ({
            ...prevForm,
            name: createUserForm.first_name?.trim() + ' ' + createUserForm.last_name?.trim()
        }))
    }, [createUserForm.first_name, createUserForm.last_name])

    const getFiles = () => {
        setFilesLoading(true)
        request(true).get(endpoints.CHANGE_OF_USE).then(response => {
            setFiles(response.data)
        }).then(() => {
            setFilesLoading(false)
        })
    }

    const downloadFile = url => {
        request(true).get(url).then(res => {
            FileSaver.saveAs(res.data?.download) 
        });
    }

    const updateLoginFormValue = (e) => {
        setLoginForm({
            ...loginForm,
            [e.target.name]: e.target.value ? e.target.value : undefined
        })
    }

    const loginFormSubmit = (e) => {
        setLoading(true)
        e.preventDefault();

        request().post(endpoints.LOGIN, loginForm).then(e => {
            setError(null)
            setLoading(false)
            sessionStorage.setItem('user-token', e.data?.token);
            session.token = e.data?.token;
            session.fetch();

            getFiles();
        }).catch(e => {
            setError(e.response?.data)
            setLoading(false)
        });
    }

    const updateCreateUserFormValue = (e) => {
        const { name, value, type, checked } = e.target;
        setCreateUserForm({
            ...createUserForm,
            [name]: type === 'checkbox' ? value === "true" ? true : false : value
        })
    }

    const createUserFormSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        const name = createUserForm.first_name.trim() + ' ' + createUserForm.last_name.trim()
        setCreateUserForm(prevForm => ({
            ...prevForm,
            name: name
        }))

        if (createUserForm.email !== createUserForm.confirm_email) {
            setError({
                errors: {
                    email: "Emails do not match"
                },
                message: "Invalid form data"
            })
            setLoading(false)
            return
        }
        request().post(endpoints.CREATE_USER, createUserForm).then(e => {
            setError(null)
            setLoading(false)
            sessionStorage.setItem('user-token', e.data?.token);
            session.token = e.data?.token;
            session.fetch();

            getFiles();
        }).catch(e => {
            setError(e.response?.data)
            setLoading(false)
        });
    }

    const logOut = (e) => {
        request(true).get(endpoints.LOGOUT).then(e => {
            sessionStorage.clear();
            session.token = null;
            window.location.reload();
        })
    }

    return (
        <Container>
            <Header page="changeofuse" />
            <Container>
                <div className={styles.heroSection}>
                    <div className={styles.contentWrap}>
                        <div className={styles.headingWrap}>
                            <h1>Stay on top of your property base with free weekly access to change-of-use data</h1>
                        </div>
                        <div className={styles.subheadingWrap}>
                            <h2>A free-to-access service from IDenteq</h2>
                        </div>
                        <p>Click below to subscribe and gain access to Excel files, updated weekly, containing change of use data, plus receive an email each time a new file is published, allowing you to stay on top of your property base, saving time and resources.</p>
                    </div>
                </div>

                <div className={styles.infoSection}>
                    <div className={styles.contentWrap}>
                        <div className={styles.headingWrap}>
                            <h2>Valuation Office Agency Change-of-Use Files Made Easy</h2>
                        </div>
                        <div className={styles.textWrap}>
                            <p className={styles.paddingBottom}>Every week the Valuation Office Agency publishes a file containing a list of all changes to Business Rates. There were over 100k changes to the Business Rates file in 2023, and finding properties which are undergoing a ‘Change of Use’ from this data can take up a significant amount of resource. In an average file, only 3% of the entries relate to properties being delisted from Business Rates and becoming a Domestic property.</p>
                            <p>IDenteq provides an easy-to-access and free-to-use service designed to save you time and resources. Taking weekly Valuation Office Agency files, we use AI to identify the properties which have undergone a change of use, and publish these as a downloadable Excel file. This reduces the time taken to scan hundreds of entries to find the ones you need to know about.</p>
                        </div>
                    </div>
                </div>

                <div className={styles.formSection}>
                    <div className={styles.contentWrap}>
                        {!sessionStorage.getItem('user-token') ? <Tabs className={styles.tabs}>
                            <TabList className={styles.tabList}>
                                <Tab className={styles.tab} selectedClassName={styles.registerSelected}>Register</Tab>
                                <Tab className={styles.tab} selectedClassName={styles.loginSelected}>Login</Tab>
                            </TabList>

                            <TabPanel>
                                <div className={styles.registerWrap}>
                                    {error ? (
                                        <div className={styles.errorBox}>
                                            <p>There was a problem with your submission. Please review the fields below.</p>
                                            <ul>
                                                {error.errors?.name ? (
                                                    <li><strong>Name:</strong> {error.errors?.name}</li>
                                                ) : <></>}
                                                {error.errors?.email ? (
                                                    <li><strong>Email:</strong> {error.errors?.email}</li>
                                                ) : <></>}
                                                {error.errors?.company ? (
                                                    <li><strong>Company:</strong> {error.errors?.company}</li>
                                                ) : <></>}
                                                {error.error ? (
                                                    <li><strong>Email:</strong> {error.error}</li>
                                                ) : <></>}
                                            </ul>
                                        </div>
                                    ) : <></>}
                                    <h2>Register for Change of Use Alerts</h2>

                                    <form onSubmit={createUserFormSubmit}>
                                        <fieldset>
                                            <legend>Name<span className={styles.fieldRequired}>(Required)</span></legend>
                                            <div className={styles.complexInput}>
                                                <div className={styles.labelledInput}>
                                                    <label htmlFor="reg-first-name">First</label>
                                                    <input id="reg-first-name" onChange={updateCreateUserFormValue} name="first_name" className="input" type="text" autoComplete="off" required />
                                                </div>
                                                <div className={styles.labelledInput}>
                                                    <label htmlFor="reg-last-name">Last</label>
                                                    <input id="reg-last-name" onChange={updateCreateUserFormValue} name="last_name" className="input" type="text" autoComplete="off" required />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Email<span className={styles.fieldRequired}>(Required)</span></legend>
                                            <div className={styles.complexInput}>
                                                <div className={styles.labelledInput}>
                                                    <label htmlFor="reg-enter-email">Enter Email</label>
                                                    <input id="reg-enter-email" onChange={updateCreateUserFormValue} name="email" className="input" type="text" autoComplete="off" required />
                                                </div>
                                                <div className={styles.labelledInput}>
                                                    <label htmlFor="reg-confirm-email">Confirm Email</label>
                                                    <input id="reg-confirm-email" onChange={updateCreateUserFormValue} name="confirm_email" className="input" type="text" autoComplete="off" required />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Company</legend>
                                            <input className={styles.companyInput} onChange={updateCreateUserFormValue} name="company" type="text" autoComplete="off" />
                                        </fieldset>
                                        <fieldset className={styles.marketingRadio}>
                                            <legend>Marketing<span className={styles.fieldRequired}>(Required)</span></legend>
                                            <div className={styles.labelledInput}>
                                                <input id="marketing-opt-in" onChange={updateCreateUserFormValue} name="marketing" value={true} checked={createUserForm.marketing === true} type="checkbox" autoComplete="off" />
                                                <label htmlFor="marketing-opt-in">Opt in</label>
                                            </div>
                                            <div className={styles.labelledInput}>
                                                <input id="marketing-opt-out" onChange={updateCreateUserFormValue} name="marketing" value={false} checked={createUserForm.marketing === false} type="checkbox" autoComplete="off" />
                                                <label htmlFor="marketing-opt-out">Opt out</label>
                                            </div>
                                        </fieldset>
                                        <div className={styles.buttonWrap}>
                                            <button id="login" disabled={loading}>
                                                {
                                                    loading ? <>
                                                        <i className='fas fa-loader fa-fw fa-spin'></i>
                                                    </> : <>
                                                        Submit
                                                    </>
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <form className={styles.loginForm} onSubmit={loginFormSubmit}>
                                    {error ? (
                                        <div className={styles.errorBox}>
                                            <p>Email address or password incorrect.</p>
                                        </div>
                                    ) : <></>}
                                    <div className={styles.inputWrap}>
                                        <div className={styles.inputField}>
                                            <div className={styles.labelContainer}>
                                                <label htmlFor="login-email">Email</label>
                                            </div>
                                            <input id="login-email" onChange={updateLoginFormValue} name="email" type="text" autoComplete="off" required />
                                        </div>
                                        <div className={styles.inputField}>
                                            <div className={styles.labelContainer}>
                                                <label htmlFor="login-password">Password</label>
                                            </div>
                                            <input id="login-password" onChange={updateLoginFormValue} name="password" type="password" autoComplete="off" required />
                                        </div>
                                        <input id="login-remember-password" type="checkbox" className={styles.loginCheckbox} />
                                        <label className={styles.loginCheckboxLabel} htmlFor="login-remember-password">Keep me signed in</label>
                                        <button className={styles.loginButton} id="login" disabled={loading}>
                                            {
                                                loading ? <>
                                                    <i className='fas fa-loader fa-fw fa-spin'></i>
                                                </> : <>
                                                    Login
                                                </>
                                            }
                                        </button>
                                        <div className={styles.pwForgotWrap}>
                                            <a className={styles.forgotPassword} href="/password-reset">Forgot your password?</a>
                                        </div>
                                    </div>
                                </form>
                            </TabPanel>
                        </Tabs>
                            :
                            <div className={styles.filesContainer}>
                                <p>Files available for download:</p>
                                {filesLoading ? (
                                    <div className={styles.loaderWrap}>
                                        <span className={styles.loader} />
                                    </div>
                                ) : (
                                    <div className={styles.filesWrap}>
                                        {
                                            files.toReversed().map(file => {
                                                return (
                                                    <p key={file.name} onClick={() => downloadFile(file.download)}>{file.name}</p>
                                                );
                                            })
                                        }
                                    </div>
                                )}
                                <button className={styles.logoutButton} onClick={logOut} id="logout">Log out</button>
                            </div>}
                    </div>
                </div>
            </Container>
            <Footer />
        </Container>
    );
}