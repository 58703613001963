import styles from './css/Technology.module.css'

export default function Technology({ module }) {
    let boxes = [
        { icon: "/images/modules/technology/Automation-Icon-i1.png", header: "Automation", text: module.technology?.automation},
        { icon: "/images/modules/technology/Data-Aggregation-Icon-I1.png", header: "Data aggregation", text: module.technology?.data_aggregation},
        { icon: "/images/modules/technology/Seamless-integration-Icon-I1.png", header: "Seamless integration", text: module.technology?.seamless_integration},
        { icon: "/images/modules/technology/Data-hosting-Icon-I1.png", header: "Data hosting", text: module.technology?.data_hosting},
        { icon: "/images/modules/technology/User-friendly-platform-Icon-I1.png", header: "User-friendly platform", text: module.technology?.user_friendly},
        { icon: "/images/modules/technology/Output-pricing-Icon-I1.png", header: "Output pricing", text: module.technology?.output_pricing},
        { icon: "/images/modules/technology/Subscription-based-service-Icon-I1.png", header: "Subscription-based service", text: module.technology?.subscription_service},
    ]

    return (
        <div className={styles.container}>
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h2>How our technology makes life easier</h2>
                </div>
                <div className={styles.boxWrapper}>
                    {boxes.map(box => (
                        <div className={styles.box}>
                            <div className={styles.iconContainer}>
                                <img src={box.icon} alt={box.header} />
                            </div>
                            <h4>{box.header}</h4>
                            <p>{box.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}