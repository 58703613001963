import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import styles from './css/PortalModule.module.css'

import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Container from '../../components/container/container';

import ModuleHero from "./ModuleHero";
import ModuleInfo from "./ModuleInfo";
import CotDataSources from "../../components/CotDataSources/CotDataSources";
import BDDataSources from "../../components/BDDataSources/BDDataSources";
import Technology from "./Technology";
import CallToAction from "./CallToAction";
import Contact from "../../components/contact/contact";

export default function PortalModule() {
    const params = useParams()
    const [module, setModule] = useState({})

    useEffect(() => {
        document.title = 'IDenteq'
        request(true).get(endpoints.MODULES + '/' + params.module_slug)
        .then(json => {
            setModule(json.data)
            document.title = json.data.title + ' | IDenteq' 
        })
        .catch(err => window.location.href = "/")
    }, [params.module_slug])

    return (
        <Container>
            <Header />
            <div>
                <ModuleHero module={module} />
                <ModuleInfo module={module} />
                {module.portal == "cotportal" ? (
                    <CotDataSources>
                        <p>All the data required to tackle common frauds is contained within the COT Portal, making it simple and efficient to use data to prevent revenue loss.</p>
                    </CotDataSources>
                ) : (
                    <BDDataSources>
                        <p>Business Data Portal has been built to meet the challenges facing utility firms today, and the solution can be deployed in full or on a modular basis.</p>
                    </BDDataSources>
                )}
                <Technology module={module} />
                <CallToAction module={module} />
                <div className={styles.contactWrap}>
                    <Contact title="Contact Us" />
                </div>
            </div>
            <Footer />
        </Container>
    );
}