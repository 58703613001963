import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import request from '../helpers/request'
import endpoints from '../helpers/endpoints'

import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'

import styles from './css/DoReset.module.css'

export default function DoReset()
{
    const [form, setForm] = useState({
        action: null,
        signature: null,
        password: null,
    })
    const [message, setMessage] = useState('')
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [passwordStrong, setPasswordStrong] = useState(false)

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    useEffect(() => {
        document.title = 'Password Reset | IDenteq'
        if (sessionStorage.getItem('api-path') === null) {
            handleWait();
        } else {
            handleReady();
        }
    }, [])

    const handleWait = () => {
        setTimeout(() => {
            if (sessionStorage.getItem('api-path') === null) {
                handleWait();
            } else {
                handleReady();
            }
        }, 250);
    }

    const handleReady = () => {
        let queryString = window.location.href.substring(window.location.href.indexOf('?') + 1);
        let signature = queryString.substring(queryString.indexOf('signature') + 10);

        request(false).post(endpoints.RESET_CHECK, {
            action: params.get('action'),
            signature: signature
        }).then(r => {
            if (r.data.valid) {
                setForm({
                    action: params.get('action'),
                    signature: signature,
                })
            } else {
                window.location.href = '/change-of-use';
            }
        }).catch(r => {
            window.location.href = '/change-of-use';
        })
    }

    const formSubmit = () => {
        if (form.password !== form.confirm_password) {
            setMessage('Passwords do not match.');
            return;
        } else {
            setMessage('')
        }

        request(false).post(endpoints.RESET_RESET, form).then(r => {
            window.location.href = '/change-of-use';
        }).catch(r => {
            // console.error(r);
            setMessage(r.response.data.errors.password);
        });
    }

    const updateFormValue = (e) => {
        if (e.target.name === 'password') {
            if (e.target.value.match(/(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/)) {
                setPasswordStrong(true)
            } else {
                setPasswordStrong(false)
            }

            setPasswordMatch(e.target.value === form.confirmPassword && passwordStrong);
        }

        if (e.target.name === 'confirm_password') {
            setPasswordMatch(e.target.value === form.password && passwordStrong);
        }

        setForm({
            ...form,
            [e.target.name]: e.target.value ? e.target.value : undefined
        });
    }

    const enterKeyDown = (e) => {
        if (e.which === 13) {
            formSubmit();
        }
    }

    return (
        <Container>
            <Header />
            <div className={styles.contentWrap}>
                <h2>Reset Password</h2>
                <div className={styles.formWrap}>
                    <p className={styles.info}>Please enter a new password for your account.</p>
                    <div>
                        {message ? (
                            <div className={styles.errorBox}>
                                <p>{message}</p>
                            </div>
                        ) : <></>}
                        <input onKeyDown={enterKeyDown} onChange={updateFormValue} name="password" placeholder="Password" className={`input ${passwordStrong ? 'color-green' : ''}`} type="password" required />
                        <input onKeyDown={enterKeyDown} onChange={updateFormValue} name="confirm_password" placeholder="Repeat Password" className={`input ${passwordMatch ? 'color-green' : ''}`} type="password" required />
                        <button onClick={formSubmit} className={styles.resetButton}>Activate</button>
                    </div>
                </div>
            </div>
            <Footer />
        </Container>
    );
}