import styles from './css/CallToAction.module.css'

import Markdown from 'markdown-to-jsx'

export default function CallToAction({ module }) {
    return (
        <div className={styles.container}>
            <div className={styles.contentWrap}>
                <div className={styles.headerWrap}>
                    <h2>{module.cta_title}</h2>
                </div>
                <div className={styles.textWrap}>
                    <Markdown>{module.cta_text}</Markdown>
                </div>
                <div className={styles.buttonWrap}>
                    <a href="/contact">Show me how</a>
                </div>
            </div>
        </div>
    ) 
}