import React, { useEffect } from 'react';

import "animate.css"
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Header from './components/header/header'
import Footer from './components/footer/footer'
import Container from './components/container/container'
import Contact from './components/contact/contact'

import './App.css';

import BDPortalBlue from './img/bd-portal-blue.png';

import SaaSIcon from './img/home/SaaS-Icon.png'
import IntegrationIcon from './img/home/Integration-Icon.png'
import ResearchDevelopmentIcon from './img/home/Research-development-Icon.png'

export default function App() {

  useEffect(() => {
    document.title = 'IDenteq | Data-driven Solutions for Utilities'
  }, [])

  return (
    <Container>
      <Header page="home" />
      {/* HOME CONTENT */}
      <Container>
        {/* Data Solutions */}
        <div id="home_DataSolutions">
          <div className="hero-text">
            <div className="heading-wrapper">
              <h1>Data-driven solutions for the utilities industry</h1>
            </div>
            <div className="subheading-wrapper">
              <h2>We’re changing the way the utilities sector uses data.</h2>
            </div>
            <div id="home_DataSolutions_button_wrap">
              <a class="button_light" href="/contact/">Get in Touch</a>
            </div>
          </div>

        </div>
        {/* Data Solutions - END */}

        {/* Fight Fraud */}
        <div id="home_FightFraud">

          <div className="heading-wrapper">
            <h2>The fight against fraud</h2>
          </div>

          <div className="text-wrapper">
            <div id="fraud_left" class="fraud_inner_wrap">
              <p>Fraud costs water and energy suppliers over £1bn per year in lost revenue. When the Theft Risk Assessment Service (TRAS) went live detectable energy theft alone was estimated to cost customers £500m per annum.</p>
            </div>

            <div id="fraud_right" class="fraud_inner_wrap">
              <p>Utility companies are exposed to Non-notification, Change of Tenancy, Synthetic ID and Eligibility Fraud on a day to day basis, and there are limited solutions available to combat them. Fraudsters are aware of process loopholes and look to exploit these.</p>
            </div>
          </div>

        </div>
        {/* Fight Fraud END */}

        {/* New Approach */}
        <div id="home_NewApproach">
          <div className="content">
            <div className="heading-wrapper">
              <h2>A new approach</h2>
            </div>

            <div id="home_NewApproach_boxes_wrap">
              {/* Saas */}
              <div class="home_box">

                <div class="home_box_image_wrap">
                  <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                    <img loading="lazy" decoding="async"
                      src={SaaSIcon}
                      alt="Icon Image for SaaS" />
                  </AnimationOnScroll>
                </div>

                <div class="home_box_text_wrap">
                  <h4 class="home_box_header">SaaS</h4>
                  <div class="home_box_description">
                    <p>Our COT Portal software is subscription based with a user friendly front end meaning no black boxes or long term commitments.</p>
                  </div>
                </div>

              </div>
              {/* Saas - END */}

              {/* Integration */}
              <div class="home_box middle_box">

                <div class="home_box_image_wrap">
                  <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                  <img loading="lazy" decoding="async"
                    src={IntegrationIcon}
                    alt="Icon Image for Integration" />
                  </AnimationOnScroll>
                </div>

                <div class="home_box_text_wrap">
                  <h4 class="home_box_header">Integration</h4>
                  <div class="home_box_description">
                    <p>We support the integration of the COT Portal into your processes and core systems. When we work together, our goal is to integrate as seamlessly as possible!</p>
                  </div>
                </div>

              </div>
              {/* Integration - END */}

              {/* Research & development */}
              <div class="home_box">

                <div class="home_box_image_wrap">
                  <AnimationOnScroll animateIn='animate__fadeTop' animatePreScroll={false} animateOnce={true}>
                  <img loading="lazy" decoding="async"
                    src={ResearchDevelopmentIcon}
                    alt="Icon Image for Research &amp; development" />
                  </AnimationOnScroll>
                </div>

                <div class="home_box_text_wrap">
                  <h4 class="home_box_header">Research &amp; development</h4>
                  <div class="home_box_description">
                    <p>We continuously research and innovate to identify new data sources to reduce fraud in utilities. Having us as part of your workflow means access to this data!</p>
                  </div>
                </div>

              </div>
              {/* Research & development - END */}

            </div>

          </div> 
        </div>
        {/* New Approach - END */}

        {/* COT Portal */}
        <div id="home_CotPortal" class="home_product_row">

          <div id="home_CotPortal_img" class="home_product_img_wrap"></div>

          <div id="cot_portal_text_wrap" class="home_product_text_wrap">
            <div class="home_inner_wrap">
              <h2>COT Portal ™</h2>
              <p>COT Portal is our award-winning SaaS data services platform for the domestic utilities industry, containing 4 independent modules to effectively manage:</p>
              <ul>
                <li>Debt and Data Quality</li>
                <li>Voids and Occupier Validation</li>
                <li>Fraud Detection and Prevention</li>
                <li>Address Quality and Monitoring</li>
              </ul>
              <div class="home_button_wrap">
                <a class="button_dark" href="/cot-portal/">Learn More</a>
              </div>
            </div>
          </div>

        </div>
        {/* COT Portal - END */}

        {/* BD Portal */}
        <div id="home_BDportal" class="home_product_row">

          <div id="bd_portal_text_wrap" class="home_product_text_wrap">
            <div class="home_inner_wrap">
              <h2>BD Portal</h2>
              <p>Business Data Portal is our commercial data services solution for the utilities industry, which utilises multiple data sources and machine learning to support customer management, fraud detection and revenue protection.</p>
              <div class="home_button_wrap">
                <a class="button_dark" href="/bd-portal/">Learn More</a>
              </div>
            </div>
          </div>

          <div class="home_product_img_wrap bd-portal-img-wrap">
            <div className='inner-wrap'>
              <img loading="lazy" decoding="async" class="img_product"
                src={BDPortalBlue} alt="Image of bd portal" />
            </div>
          </div>
          {/* <div id="home_BDPortal_img" class="home_product_img_wrap"></div> */}

        </div>
        {/* BD Portal - END */}

        {/* PMaaS */}
        <div id="home_PMaaS" class="home_product_row">
          <div id="home_PMaaS_img_wrap" class="home_product_img_wrap"></div>

          <div id="home_PMaaS_text_wrap" class="home_product_text_wrap">
            <div class="home_inner_wrap">
              <h2>Project Management as a Service (PMaaS)</h2>

              <p>Project Management as a Service (PMaaS) provides access to project
                specialists, covering a wide range of roles, working remotely or on
                site.</p>

              <div class="home_button_wrap">
                <a class="button_dark" href="/PMaaS/">Learn More</a>
              </div>
            </div>
          </div>
        </div>
        {/* PMaaS - END */}

      </Container>
      {/* HOME CONTENT - END */}
      <div className="contact-section">
        <Contact page="home" />
      </div>
      <Footer page="home" />
    </Container>
  );
}